
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { withTranslation } from 'react-i18next';

import logger from '../utility/logger.mjs';
import { PrettyTime, Timezone, TimeLeftTo } from './PrettyTime.js';
import { DataList } from '../components/DataList.js';

import { fetchKeyValues, fetchUiDesc } from '../server/serverOperation.js';

import ReplayIcon from '@mui/icons-material/Replay';
import { IconButton, CircularProgress } from '@mui/material';

function PureDeviceDetails({ t, dev, showDeviceName=false }) {
  const privileges = useSelector(state => state.devices.device.find( o => o.id === dev.id).privileges);

  const { data:uiDesc, refetch:refetchUiDesc, isLoading:uiDescIsLoading } = useQuery(
    ["uiDesc", dev?.id],
    () => fetchUiDesc(dev?.id),
    { enabled: false}
  ); 
  const { data:keyValues, isLoading:keyValuesIsLoading } = useQuery(
    ["keyValues", dev.id],
    () => {
      if(dev?.activationTime == null){
        throw Error("Device not activated: key values not fetched");
      }
      const queryParams = {
        bucket:     'DeviceData',
        deviceType: dev.type,
        deviceUUID: dev.uuid,
        fields:     [...new Set(uiDesc?.map(row => row.influxKey)?.filter(n => n))],
      }
      return fetchKeyValues(queryParams);
    },
    { enabled: !!uiDesc && !dev?.dataIsLoaded }
  );

  const lastSeen = () => {
    if(dev?.lastSeen != null){
      return <PrettyTime format={"DATETIME_SHORT"}>{dev?.lastSeen}</PrettyTime> 
    }
    if(keyValues?.latestValues?.time){
      return <PrettyTime format={"DATETIME_SHORT"}>{keyValues.latestValues.time}</PrettyTime> 
    }
    if(dev?.activationTime == null){
      return "--"
    }
    if(uiDescIsLoading || keyValuesIsLoading){
      return <CircularProgress size={15} />
    }
    return (
      <IconButton onClick={refetchUiDesc}>
        <ReplayIcon  fontSize={"small"} />
      </IconButton>
    )
  }

  const status = dev?.deviceStatus ?? "UNKOWN";
  const timeLeft = dev?.activationTime 
    ? <TimeLeftTo duration={{years: 2}}>{dev?.activationTime}</TimeLeftTo>
    : "?";
  const activationTime = dev?.activationTime != null
    ? <PrettyTime format={"DATE_SHORT"}>{dev?.activationTime}</PrettyTime> 
    : "?";

  // array of arrays: [caption, data, [color]] (color is optional)
  const dataDetails = () => {
    const basics = [
      [t("device-type"), dev.type],
      [t("uuid"), dev.uuid],
      [t("status"), t(status), dev.deviceStatus === "ONLINE" ? "green" : "red"],
      [t("last-seen"), lastSeen()],
      [t("activated"), activationTime],
      [t("time-left"), timeLeft],
      [t("timezone"), <Timezone offset={dev?.offset}/>],
      [t("your-privileges"), t(privileges)],
    ]

    if(showDeviceName){
      return [t("device-name"), dev.name].concat(basics)
    }
    return basics
  } 
  
  return <DataList data={dataDetails()} />
}
const DeviceDetails = withTranslation()(PureDeviceDetails);

export {
  DeviceDetails
};