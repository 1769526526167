import { withTranslation } from 'react-i18next';
import { LangaugeSelector } from './LanguageSelector.js';
import { useSelector, useDispatch } from 'react-redux';

import { selectBrand, selectLoginType, selectThemeMode, themeModeSet } from '../data/uiSlice.js';

import {
  Box,
  IconButton,
  Link,
  Skeleton,
  Tooltip,
} from '@mui/material/';
import LogoutIcon from '@mui/icons-material/Logout';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import XortecLogo from '../utility/mainiot-logo.png';
import PicotecLogo from '../utility/picote-logo-cropped.png';

const logout = () => {
  localStorage.clear();
  window.location.href = '/';
}

const branding = {
  default : {
    mainLogo: {
      src: XortecLogo,
      alt: "Xortec logo",
      style: {
        height: '20px', 
        position: 'absolute', 
        left: '50%', 
        top: '50%', 
        transform: 'translate(-50%,-50%)'
      },
    },
    showDarkModeSwitch: false,
    helpUrl: 'http://main-iot.com/support',
  },
  picote : {
    mainLogo: {
      src: PicotecLogo,
      alt: "Picote logo",
      style: {
        height: '35px', 
        position: 'absolute', 
        left: '50%', 
        top: '50%', 
        transform: 'translate(-50%,-50%)'
      },
    },
    showDarkModeSwitch: true,
    helpUrl: 'https://www.picotegroup.com/products/product-pressure-guard',
  },
}

const PureHeader = ({t}) => {
  const dispatch = useDispatch();
  const loginType = useSelector(selectLoginType);
  const currentBrand = useSelector(selectBrand);
  const mode = useSelector(selectThemeMode);
  const brand = !!branding?.[currentBrand] ? currentBrand : "default";
  const handleThemeModeChange = () => {
    dispatch(themeModeSet(mode === "light" ? "dark" : "light"));
  };
  if(loginType === "none"){
    return <Skeleton height={50} variant="rectangular" />
  }
  return (
    <Box 
      sx={{
        position: 'relative',
        width: '100%', 
        height: '50px',
        backgroundColor: 'primary.main'
        }}>
      <img 
        src={branding[brand].mainLogo.src} 
        alt={branding[brand].mainLogo.alt}
        style={branding[brand].mainLogo.style} 
        />
      <Box sx={{position: 'absolute', left: '3%', top: '50%', 
          transform: 'translate(0,-50%)', color: 'white', display:'flex'}}>
        <Tooltip title={t("log-out")}>
          <IconButton aria-label="logout" onClick={logout} >
            <LogoutIcon style={{ color: 'white' }} />
          </IconButton>
        </Tooltip>
        { branding[brand].showDarkModeSwitch ? 
          <Box sx={{ml:.5}}>
            <Tooltip title={ mode === "light" ? t("light-mode") : t("dark-mode")}>
              <IconButton aria-label="logout" onClick={handleThemeModeChange} sx={{ color: 'white' }} >
                { mode === "light" ? <Brightness7Icon /> : <Brightness4Icon />}
              </IconButton>
            </Tooltip>
          </Box>
          : false }
      </Box>
      <Box sx={{position: 'absolute', right: '2%', color: 'white', display:'flex'}}>
        <Box sx={{mt:1.6, mr:".2em"}}>
          <Tooltip title={t("header-help-link-tooltip")}>
            <Link 
              style={{color:'white', textDecoration: 'none'}} 
              href={branding[brand].helpUrl}
              target="_blank" 
              variant="contained">
                {t("header-help-link")}
              </Link>
          </Tooltip>
        </Box> 
        <Box sx={{mt:.5}}>
          <LangaugeSelector sx={{fontWeight: 'regular', color: "white"}} />
        </Box>
      </Box>
    </Box>
  );
}
const Header = withTranslation()(PureHeader);
export default Header;
