import { withTranslation } from 'react-i18next';
import { PDFDownloadLink } from "@react-pdf/renderer";

import { Button } from '@mui/material/';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import { RaportLayout } from './RaportLayout.js' 

function PureDownloadRaport({t, data, rebootTime, dateNow, range, charts, disabled, referece}) {
  // if(disabled){
  //   return (
  //     <Button
  //       variant="outlined" 
  //       endIcon={<PictureAsPdfIcon/>}
  //       disabled={true}>
  //         {t("report")}
  //     </Button>
  //   );
  // }
  const date = new Date().toJSON().slice(0, 10);
  const fileName = `${data.name.replace(/\s/g, '')}-${dateNow}.pdf`
  return (
    <PDFDownloadLink 
      fileName={fileName}
      document={
        <RaportLayout
          data={data} 
          rebootTime={rebootTime}
          dateNow={dateNow}
          range={range} 
          charts={charts}
      />} 
    >
      { charts.length > 0 &&
        <Button
          color="secondary"
          ref={referece}
          variant="outlined" 
          style={{  }}
          disabled={ disabled }
          endIcon={<PictureAsPdfIcon/>}
        >
          {t('download-report')}
        </Button>
      }
    </PDFDownloadLink>
  );
}

const DownloadRaport = withTranslation()(PureDownloadRaport);
export { DownloadRaport };