import { withTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

const PureButtonWide = ({t, onClick, text, width="90%", margin='25px 5px', sx={}, disabled=false, isLoading=false}) => {
  if(width === "half") width = "45%";

  return ( 
    <LoadingButton 
      className="picoteBack" 
      sx={{width: {width}, margin, ...sx}} 
      variant="contained"
      onClick={onClick}
      disabled={disabled}
      loading={isLoading}
      >
        {t(text)}
    </LoadingButton>
  )
}

const PureButtonSmall = ({t, onClick, text, width=false, margin='25px 5px', sx={}, disabled=false}) => {
  if(width === "half") width = "45%";

  return ( 
    <Button
      color="secondary"
      variant="outlined" 
      size="small" 
      sx={{width: {width}, margin, ...sx}} 
      onClick={onClick}
      disabled={disabled}>
        {t(text)}
    </Button>
  )
}


const ButtonSmall = withTranslation()(PureButtonSmall);
const ButtonWide = withTranslation()(PureButtonWide);

export {
  ButtonWide,
  ButtonSmall
};