import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from 'react-i18next';

import logger from '../utility/logger.mjs';
import { round } from '../utility/function.mjs';
import { unit } from '../utility/influxChartConfig.mjs';
import useParamSelector from '../data/useParamSelector.js'
import { 
  currentSettingsSet,
  selectCurrentData,
  selectComponentValues,
  selectCurrentComponentDesc,
} from '../data/devicesSlice.js';
import { selectUnitSystem } from "../data/uiSlice.js";

import { 
  Box,
  Input as MuiInput,
  IconButton as MuiIconButton,
  Slider,
  styled,
  TextField as MuiTextField,
} from '@mui/material';
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';

// style reference value mark on basis of it position
const Ranger = styled(Slider)({
  '& .MuiSlider-mark': {
    backgroundColor: '#bfbfbf',
    height: 30,
    width: 1.5,
    '&.MuiSlider-markActive': {
      opacity: 1,
      backgroundColor: 'currentColor',
    },
  },
  '& .MuiSlider-markLabel[data-index="0"]': {
    transform: "translateX(-10%)",
  },
  '& .MuiSlider-markLabel[data-index="1"]': {
    transform: "translateX(-50%)",
  },
  '& .MuiSlider-markLabel[data-index="2"]': {
    transform: "translateX(-90%)",
  }
});

const Input = styled(MuiInput)`
  width: 3.4em;
`;

const IconButton = styled(MuiIconButton)`
  padding: 3px;
`;

function PureRangeSlider({ t, block }) {
  const INPUT_BUTTON_SIZE = "large"; // "small" | "medium" | "large"
  const NO_VALUE = "--";

  const dispatch = useDispatch();
  const disabled = block.disabled ?? false;
  const componentId = block.id;
  const data = useParamSelector(selectCurrentComponentDesc, componentId);
  const desc = data.description;
  const latestValue = useSelector(selectCurrentData)?.latestValues?.[data?.influxKey];
  const width = desc?.SIZE === "half" ? "43%" : "90%";
  const position = desc?.POSITION ?? "center";
  const jsonkeysForValues =  data.jsonkeys.slice(0,2);
  //const jsonkeysForMinMax = data.jsonkeys.slice(2,4);
  const valInd = data.JSONValInd;
  const rawValues = useParamSelector(selectComponentValues, componentId);
  const currentValues = rawValues.slice(0,2);
  
  const minMaxValues = rawValues.slice(2,4);
  const unitSystem = useSelector(selectUnitSystem);
  const unitSymbol = unit[unitSystem][data.influxKey].symbol;

  const convertValues = (v, decimals = 0) => {
    if(Array.isArray(v)){
      return v.map( value => unit[unitSystem][data?.influxKey].conv(value,decimals) );
    }
    return unit[unitSystem][data?.influxKey].conv(v);
  }
  const convertValuesBack = (v, decimals = 0) => {
    if(Array.isArray(v)){
      return v.map( value => unit[unitSystem][data?.influxKey].convBack(value,decimals) );
    }
    return unit[unitSystem][data?.influxKey].convBack(v);
  }
  const [values, setValues] = useState(convertValues(currentValues));
  const maxValue = minMaxValues?.[1] ?? convertValues(desc.MAX_VAL,0);
  const minValue = minMaxValues?.[0] ?? convertValues(desc.MIN_VAL,0);
  const step = maxValue - minValue > 20 ? 1 : 0.1;
  
  const referenceValueMark = () => {
    if(latestValue == null){
      return [];
    }
    let convertedRefValue = round(convertValues(latestValue),1);
    let formatedRefValue = convertedRefValue;
    if(formatedRefValue > maxValue){
      formatedRefValue = maxValue;
    }
    else if(formatedRefValue < minValue){
      formatedRefValue = minValue;
    }
    // Reference value is styled accordinly of it's index (0...2) in the mark array.
    // In oreder ot change style chooce a different index.
    let i = 1;
    const peripheryDistance = (maxValue - minValue) / 4;
    if(formatedRefValue - minValue < peripheryDistance ){
      i = 0;
    }
    else if(maxValue - formatedRefValue < peripheryDistance ){
      i = 2;
    }
    return Array(3).fill({ value:minValue, label:null }).toSpliced(i, 1,
      {
        value: convertedRefValue,
        label: `${t("latest-value")}: ${formatedRefValue} ${unitSymbol}`
      }).concat([{ value: maxValue, label: null }]);
  };

  const checkValue = (value, type) => {
    if(value < minValue || value > maxValue) {
      //logger.info("checkValue out of range");
      return NO_VALUE;
    }
    if(type === "low" && value > values[1]){
      //logger.info("checkValue low > high",type, value, values[1]);
      return NO_VALUE;
    }
    if(type === "high" && value < values[0]){
      //logger.info("checkValue low < high",type, value, values[0]);
      return NO_VALUE;
    }
    return value
  }
  
  // const setInputValue = (value) => {
  //   pureSetInputValue(formatValue(value));
  //   setSliderValue(value);
  // }

  const setSettings = (newValues) => {
    dispatch(currentSettingsSet({
      values: convertValuesBack(newValues,2),
      jsonkeys: jsonkeysForValues,
      valInd
    }));
    setValues(round(newValues,2));
  }

  // const handleBlur = () => {
  //   if (inputValue < minValue) {
  //     setInputValue(minValue);
  //   } else if (inputValue > maxValue) {
  //     setInputValue(maxValue);
  //   }
  // };

  const handleLowInputChange = (event) => {
    const numberValue = Number(event.target.value);
    if(typeof numberValue === 'number' && numberValue >= minValue && numberValue <= maxValue){
      const newValues = [numberValue, Math.max(numberValue, values[1])];
      setSettings(newValues);
    }
  };

  const handleHighInputChange = (event) => {
    const numberValue = Number(event.target.value);
    if(typeof numberValue === 'number' && numberValue >= minValue && numberValue <= maxValue){
      const newValues = [Math.min(values[0], numberValue), numberValue];
      setSettings(newValues);
    }
  };

  const onSliderChange = (event, newValues, activeThumb) => {
    if (!Array.isArray(newValues)) {
      return;
    }

    if (activeThumb === 0) {
      setValues([Math.min(newValues[0], values[1]), values[1]]);
    } else {
      setValues([values[0], Math.max(newValues[1], values[0])]);
    }
  };

  const onChangeCommittedFunction = ( event, value ) => {
    setSettings(value);
  };

  const onAddClick = (type) => {
    const i = type === "low" ? 0 : 1
    const pureMaxValue = convertValuesBack(maxValue);
    if (checkValue(values[i]) === NO_VALUE){
      const defaultValue =
          type === "low" 
            ? (maxValue - values[1]) / 2 
            : (minValue + values[0]) / 2;
      setSettings(values.toSpliced(i, 1, defaultValue));
    }
    else if (values[i] < maxValue) {
      if(type === "low" && values[0] + step > values[1]){
        const newValues = Array(2).fill(Math.min(values[0] + step, maxValue));
        setSettings(newValues)
      }
      else {
        if(convertValuesBack(values[i] + step) < pureMaxValue){
          const trimmedValue = round(values[i] + step, ( step >= 1 ? 0 : 1 ));
          setSettings(values.toSpliced(i, 1, trimmedValue));
        }
        else {
          setSettings(values.toSpliced(i, 1, maxValue));
        }
      }
    }
  }

  const onRemoveClick = (type) => {
    const i = type === "low" ? 0 : 1
    if (checkValue(values[i]) === NO_VALUE){
      const defaultValue = type === "low" 
        ? maxValue - values[1] / 2 
        : minValue + values[0] / 2
      setSettings(defaultValue);
    }
      else if (values[i] > minValue) {
        if(type === "high" && values[1] - step < values[0]){
          const newValues = Array(2).fill(Math.max(values[1] - step, minValue));
          setSettings(newValues)
        }
        else {
          setSettings(values.toSpliced(i, 1, Math.max(values[i] - step, minValue)));
        }
      }
    }

  // function valuetext(value) {
  //   return `${value}°C`;
  // }

  const formatInputValue = (type) => {
    const chekedValue = checkValue(values[type === "low" ? 0 : 1], type);
    if(isNaN(chekedValue)){
      return 0;
    }
    return chekedValue.toFixed(step < 1 ? 1 : 0)
  }

  const LowInput = () => {
    return (
      <Box>
        { t("label-" + jsonkeysForValues[0]) + ": " } 
        <span style={{whiteSpace: 'nowrap'}}>
          <IconButton 
            aria-label="remove" 
            onClick={() => onRemoveClick("low")} 
            disabled={disabled}
          >
            <KeyboardArrowLeftOutlinedIcon fontSize={INPUT_BUTTON_SIZE}/>
          </IconButton>
          <Input
            disabled={disabled}
            value={formatInputValue("low")}
            size="small"
            onChange={handleLowInputChange}
            // onBlur={handleBlur}
            InputProps={{
              step: step,
              min: minValue,
              max: maxValue,
              inputmode: 'numeric',
              pattern: '[0-9]*',
              'aria-labelledby': 'input-range-slider-low',
            }}
          /> 
          <span style={{marginLeft:'-23px', marginTop: '-40px'}}> {unitSymbol} </span>
          <IconButton 
            aria-label="add" 
            onClick={() => onAddClick("low")} 
            disabled={disabled}
          >
            <KeyboardArrowRightOutlinedIcon fontSize={INPUT_BUTTON_SIZE}/>
          </IconButton>
        </span>
      </Box>
    )
  }

  const HighInput = () => {
    return(
      <Box>
        { t(`label-${jsonkeysForValues[1]}`) + ": " } 
        <span style={{whiteSpace: 'nowrap'}}>
          <IconButton 
            aria-label="remove" 
            onClick={() => onRemoveClick("high")} 
            disabled={disabled}
          >
            <KeyboardArrowLeftOutlinedIcon fontSize={INPUT_BUTTON_SIZE}/>
          </IconButton>
          <Input
            disabled={disabled}
            value={formatInputValue("high")}
            size="small"
            onChange={handleHighInputChange}
            // onBlur={handleBlur}
            InputProps={{
              step: step,
              min: minValue,
              max: maxValue,
              inputmode: 'numeric',
              pattern: '[0-9]*',
              'aria-labelledby': 'input-range-slider-high',
            }}
          /> 
          <span style={{marginLeft:'-23px', marginTop: '-40px'}}> {unitSymbol} </span>
          <IconButton 
            aria-label="add" 
            onClick={() => onAddClick("high")} 
            disabled={disabled}
          >
            <KeyboardArrowRightOutlinedIcon fontSize={INPUT_BUTTON_SIZE}/>
          </IconButton>
          {/* {absoluteLimit()} */} 
        </span> 
      </Box>
    )
  }

  return (
    <Box 
      sx={{ 
        m: "0 0.5em 1em", 
        width: `${width}`, 
        float: `${position}`,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Box sx={{mb:2}}>
        <Ranger
          disabled={disabled}
          key={`range-slider--${data.influxKey}-${componentId}`}
          value={values} 
          aria-labelledby="track-range-slider"
          //getAriaValueText={valuetext}
          //valueLabelDisplay="auto"
          marks={referenceValueMark()}
          step={step}
          min={minValue}
          max={maxValue}
          onChangeCommitted={onChangeCommittedFunction}
          onChange={onSliderChange}
          disableSwap
        />
      </Box>
      <Box 
        sx={{ 
          display: "flex",
          justifyContent: "space-around",
          gap: "10%",
          }}
      >
        <LowInput />
        <HighInput />
      </Box>
    </Box>
  );
}
export const RangeSlider = withTranslation()(PureRangeSlider);
