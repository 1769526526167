import React from "react";
import './App.css';
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';

import { themesAndModes } from "./theme.js";
import { selectBrand, selectThemeMode } from "./data/uiSlice.js";
import Header from './components/Header.js';
import Footer from './components/Footer.js';
import { ConfirmDialogProvider } from './components/ConfirmDialog.js';

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// const theme = createTheme({
//   palette: {
//     mode: 'dark',
//     primary: {
//       main: '#d20630',
//     },
//     secondary: {
//       main: '#c8baa1',
//     },
//     background: {
//       default: '#242424',
//     },
//   },
//   typography: {
//     fontFamily: [
//       'Raleway', 
//       'sans-serif',
//     ].join(','),
//   },
// });
const inputGlobalStyles = <GlobalStyles styles={{ 
  '.tick text': {
  'position': 'absolute !important',
  'font-size': '12px'
  },
  '.tick text:hover': {
    'position': 'absolute !important',
    'z-index': '1000 !important',
    'font-size': '15px'
  },
  'body': {
  'margin': 0,
  'font-family': "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  '-webkit-font-smoothing': 'antialiased',
  '-moz-osx-font-smoothing': 'grayscale',
}

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }

// .leaflet-container {
//   width: 100%;
//   height: 100vh;
// }'
}}/>;

// const inputGlobalStyles = <GlobalStyles styles={{ 
//   '.xortecBack': {'background-color': '#4d8dc1 !important'},
//   '.picoteBack': {'background-color': '#d20630 !important'},
//   '.xortecFore': {'color': '#4d8dc1 !important'},
//   '.App': {'background-color': '#242424 !important', 'color': '#FFFFFF'},
//   h3: {
//     'text-transform': 'uppercase',
//     'color': '#FFFFFF',//'#586E71',
//     'text-align': 'center',
//     'font-size': '16px',
//     'font-weight': 'normal'
//   },
//   h4: {
//     'color': '#FFFFFF',//'#586E71',
//     'text-align': 'center',
//     'text-align': 'left',
//     'font-size': '16px',
//     'font-weight': 'normal'
//   },
//   '.tick text': {
//     'position': 'absolute !important',
//     'font-size': '12px'
//   },
//   '.tick text:hover': {
//     'position': 'absolute !important',
//     'z-index': '1000 !important',
//     'font-size': '15px'
//   }
// }} />;

function App() {
  const brand = useSelector(selectBrand);
  const mode = useSelector(selectThemeMode);

  return (
    <>
      <ThemeProvider theme={themesAndModes(brand,mode)}>
        <CssBaseline />
        {inputGlobalStyles}
        <ConfirmDialogProvider>
          <ToastContainer 
            hideProgressBar 
            autoClose={12000} // 12s
            theme={mode}
            />
          <Container className="App" sx={{  display: "flex",
  flexDirection: "column"}} maxWidth="100%" disableGutters="true">
              <Header />
              <Container sx={{minHeight:300}} maxWidth="sm" disableGutters="true">
                <Outlet />
              </Container>
              <Footer />
          </Container>
        </ConfirmDialogProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
