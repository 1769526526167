import { withTranslation } from 'react-i18next';
import PhoneInputForm from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useSelector } from 'react-redux';

import { selectCurrentCountry, selectPrivileges } from '../../data/devicesSlice';
import { powerOver, userTypes, twilioCountries } from '../../utility/userConfig.mjs';
import UserTypeIcon from '../UserTypeIcon';

import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select 
} from '@mui/material';


const PurePhonenumberInput = ({t, number, setNewNumber, disabled, defaultUserType, disableUserTypeSelect, noSuperuserSelected}) => {
  const country = useSelector(selectCurrentCountry);
  const privileges = useSelector(selectPrivileges);

  const handleNumberChange = (value, event) => {
    setNewNumber( prev => {
      return {...prev, phonenumber: `+${value}`, country: event.countryCode}
    });
  }

  const handleSelect = (event) => {
    setNewNumber( prev => {return {...prev, userType: event.target.value}})
  };

  const shownUserTypes = userTypes.slice( noSuperuserSelected ? 0 : 1).filter( type => powerOver(privileges,type));
  
  return (
    <>
      <PhoneInputForm
        inputStyle={{width:'240px'}}
        country={twilioCountries.includes(country) ? country : 'us'}
        preferredCountries={['us','fi','se','au']}
        onlyCountries={twilioCountries}
        value={number.phonenumber}
        placeholder={t('phonenumber')}
        onChange={ handleNumberChange }
        disabled={ disabled }
      />
      { !disableUserTypeSelect && 
        <FormControl  sx={{ m: 1, minWidth: 120 }} size="small" disabled={disabled}>
          <InputLabel id="select-label">User type</InputLabel>
          <Select
            labelId="select-standard-label"
            id="select-user-type"
            //value={privileges}
            defaultValue={defaultUserType ?? userTypes[2]}
            onChange={handleSelect}
            label={t("user-type")}
            
          >
            { shownUserTypes
              .map( type => <MenuItem value={type}><UserTypeIcon userType={type} fontSize="small"/> {t(type)}</MenuItem>)
            }
          </Select>
        </FormControl>
      }
    </>
  )
}

const PhonenumberInput = withTranslation()(PurePhonenumberInput);
export default PhonenumberInput;