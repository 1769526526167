import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import i18next from 'i18next';
// FRO PUBLIC
import TelegramLoginButton from 'react-telegram-login';

import logger from '../utility/logger.mjs';
import {
  countryToLanguage,
  localesByCountry,
} from '../utility/userConfig.mjs';
import {
  devicesAdded,
  deviceAdded,
  privilegesSet,
} from '../data/devicesSlice.js';
import {
  uiInit,
  brandSet,
  loginTypeSet
} from '../data/uiSlice.js';

import { AlertError } from './Alert.js';
import Settings from "../pages/Settings.js";
import DeviceInfo from "../pages/DeviceInfo.js";
import {
  checkToken,
  getDevicesByPhonenumber,
  checkTelegramHash,
  checkURLcode,
  getDeviceByUUID,
} from "../server/serverOperation.js";

import {
  CircularProgress,
  Box
} from '@mui/material';

const IS_PUBLIC_VERSION = Number(process.env.REACT_APP_IS_PUBLIC_VERSION);
const TELEGRAM_BOT_NAME = process.env.REACT_APP_TELEGRAM_BOT_NAME;
// Telegram mock response for testin
var mockResponse = false;
if (!IS_PUBLIC_VERSION) {
  mockResponse = JSON.parse(process.env.REACT_APP_TELEGRMA_MOCK_RESPONSE)
}

const TelegramLogger = ({ t, urlCodeLogin = false, telegramLinkLogin = false }) => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(false);
  const [telegramResponse, setTelegramResponse] = useState(IS_PUBLIC_VERSION ? false : mockResponse);
  const [isPinOK, setIsPinOK] = useState(false);
  const { urlParm } = useParams();
  if (!urlCodeLogin && !telegramLinkLogin) {
    dispatch(loginTypeSet("landingPage"));
  }
  if (errorMessage) {
    dispatch(loginTypeSet("landingPage"));
  }

  const { data: tgButtonCheckData, isLoading: tgButtonIsLoading, isSuccess: telegramLinkLoginSuccess } = useQuery(
    ["checkTelegramHash", telegramResponse],
    ({ queryKey }) => {
      const [_, telegramResponse] = queryKey;
      return checkTelegramHash(telegramResponse);
    },
    { enabled: !!telegramResponse }
  );

  const { data: urlCodeCheckData, isLoading: urlCodeCheckDataIsLoading, isSuccess: urlCodeCheckIsSuccess, isError: urlCodeCheckDataIsError } = useQuery(
    ["checkToken", urlParm],
    async () => {
      const res = await checkURLcode(urlParm);
      if (!res.found) {
        setErrorMessage(res?.error);
        throw new Error('urlCodeCheckData' + res?.error);
      }
      return res
    },
    { enabled: urlCodeLogin }
  );

  const { data: tgLinkCheckData, isLoading: tgLinkCheckIsLoading, isSuccess: telegramLinkSuccess } = useQuery(
    ["checkToken", urlParm],
    async () => {
      const res = await checkToken(urlParm);
      if (!res.ok) {
        setErrorMessage(res?.error);
        throw new Error('tgLinkCheck ' + res?.error);
      }
      return res
    },
    { enabled: telegramLinkLogin }
  );

  const { data: devices, isLoading: devicesIsLoading, isSuccess: fetchDevicesSuccess } = useQuery(
    ["getDevices"],
    () => {
      if (urlCodeLogin) {
        return getDeviceByUUID(urlCodeCheckData.uuid)
      }
      else {
        const phoneId = tgLinkCheckData?.appUserPhoneId ?? tgButtonCheckData.appUserPhoneId;
        //console.log("phoneId",phoneId, tgLinkCheckData?.appUserPhoneId, tgLinkCheckData, tgButtonCheckData);
        return getDevicesByPhonenumber(phoneId);
      }
    },
    { enabled: !!tgButtonCheckData || !!tgLinkCheckData || isPinOK }
  );

  console.log("devices", devices)

  if (!!devices) {
    if (urlCodeLogin) {
      console.log("terms", devices)
      // const locales = localesByCountry(devices?.country);
      // console.log("locales",devices,locales);
      dispatch(devicesAdded([{ ...devices, dataIsLoaded: false }]));
      dispatch(uiInit({
        id: devices.id,
        loginType: "pin",
        // ...locales,
      }));
      i18next.changeLanguage(countryToLanguage(devices?.country));
      dispatch(privilegesSet({ deviceId: devices.id, privileges: "superadmin" }));
      dispatch(brandSet(urlCodeCheckData.brand))
    }
    else {
      const {
        deviceId: id,
        appUserPhoneId: userPhoneId,
        language,
        unitSystem,
        hourCycle,
        termsAccepted,
        privacyPolicyAccepted,
        brand
      } = tgLinkCheckData ?? tgButtonCheckData;
      //console.log("termsAccepted", termsAccepted, privacyPolicyAccepted);
      //const country = urlCodeLogin ? devices.country : devices.find( device => device.id === id ).country;
      const augmentedDeviceList = devices.map(dev => ({ ...dev, dataIsLoaded: false }));
      dispatch(devicesAdded(augmentedDeviceList));
      dispatch(uiInit({
        id,
        userPhoneId,
        language,
        unitSystem,
        hourCycle,
        termsAccepted,
        privacyPolicyAccepted,
        loginType: "telegram",
        brand
      }));
      i18next.changeLanguage(language);
    }
  }

  useEffect(() => {
    setErrorMessage(tgLinkCheckData?.error ?? false);
  }, [tgLinkCheckData]);

  if (tgButtonIsLoading ||
    urlCodeCheckDataIsLoading ||
    tgLinkCheckIsLoading ||
    devicesIsLoading) {
    return <Box sx={{ mt: 10 }}><CircularProgress /></Box>
  }

  if (urlCodeLogin && !isPinOK && !!urlCodeCheckData?.isActive) {
    dispatch(deviceAdded(urlCodeCheckData));
    return (
      <DeviceInfo
        setIsPinOK={setIsPinOK}
        errorMessage={errorMessage}
      />
    )
  }

  if (!fetchDevicesSuccess && !devices) {
    if (errorMessage && (urlCodeLogin || telegramLinkLogin)) {
      return (
        <Box sx={{ m: 10 }}>
          <AlertError text={errorMessage} title={false} />
        </Box>
      )
    }
    return (
      <Box sx={{ m: 7 }}>
        <TelegramLoginButton
          botName="MainIoTDev_bot"
          dataOnauth={(response) => setTelegramResponse(response)}
        //language={language}
        />
        <br />
        {!!errorMessage && <AlertError text={errorMessage} title={false} />}
      </Box>
    )
  }

  let pagenNbr = telegramLinkLoginSuccess ? 2 : 0;
  return (
    <>{fetchDevicesSuccess && devices && <Settings openPage={pagenNbr} />}</>
  )
}

export default TelegramLogger;
