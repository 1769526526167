import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useMutation } from "react-query";
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import logger from '../utility/logger.mjs';
import { updatePin } from "../server/serverOperation.js";
import { selectCurrentDeviceId } from '../data/uiSlice.js';
import { selectPrivileges } from '../data/devicesSlice';
import { AlertError, AlertSuccess } from "./Alert.js";
import { ButtonWide } from './Button.js';
import { HelpText } from "./ReadMore.js";

import { Input, Typography } from '@mui/material/';

const PurePinEditor = ({ t, inDialog = false, nonactive = false, setPinIsSet }) => {
  const deviceId = useSelector(selectCurrentDeviceId);
  const privileges = useSelector(selectPrivileges);
  const [pin, setPin] = useState("");
  const [pinInputColor, setPinInputColor] = useState("grey");

  const disbaled = nonactive || (privileges !== "superadmin");

  const infoText = () => {
    if (disbaled) {
      return <>{t("no-privileges-to-change-pin-text")}</>
    }
    return (
      <>
        {t("pin-change-info")}
        <HelpText
          text={t("pin-change-info-more")}
          buttonStyle={{ marginRight: -30 }}
        />
      </>
    )
  };

  const { mutate: mutatePin, isLoading, error } = useMutation(
    updatePin, // parameter {deviceId, newPin}
    {
      onSuccess: async (data) => {
        setPin("");
        toast.success(<AlertSuccess title={""} text="pin-is-saved" />, {
          toastId: "pin-is-saved"
        });
      },
      onError: (error) => {
        logger.error(error);
        toast.error(<AlertError title={""} text="SERVER ERROR: pin not saved" />, {
          toastId: "pin-error"
        });
      }
    }
  );

  function handlesaveclick() {
    if (pin.length === 6) {
      mutatePin({ deviceId, newPin: pin });
    }
    else {
      toast.error(<AlertError title={""} text="pin-error" />, {
        toastId: "pin-error"
      });
    }
  };

  const handleChange = (e) => {
    const valueIsValid =
      e.target.value === ""
      || (/^[0-9]+$/.test(e.target.value) && e.target.value.length <= 6)
    if (valueIsValid) {
      setPin(e.target.value);
    }
  }

  useEffect(() => {
    setPinInputColor(
      pin.length === 6
        ? "green"
        : pin.length === 0
          ? "grey"
          : "red"
    );
  }, [pin])

  return (
    <div style={{ textAlign: "left" }}>
      <Typography sx={{ textAlign: 'left' }} variant="h2">{t("pin-code")}</Typography>
      <p>{infoText()}</p>
      <div style={{ textAlign: "center", clear: "both" }}>
        <Input
          id="newPin"
          type="text"
          name="devicename"
          placeholder={t("enter-new-pin")}
          value={pin}
          onChange={handleChange}
          inputProps={{ style: { textAlign: 'center', color: pinInputColor } }}
          disabled={disbaled}
        />
        <ButtonWide
          width="28%"
          text="save"
          onClick={handlesaveclick}
          disabled={disbaled}
          isLoading={isLoading}
        />
      </div>
    </div>
  )
}

const PinEditor = withTranslation()(PurePinEditor);
export default PinEditor;