import { withTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material/';

const formatDataObject = (key, value, color="default") => {
  return {key: `${key}:`, value, color}
}

const formatData = (dataArr) => {
  return dataArr.map( row => formatDataObject(...row))
}

function PureDataList({ t, data }) { // LIS: data parametri
  const formattedData = formatData(data);

  return (
    <Table size="small" aria-label="purchases">
      <TableBody>
        {formattedData.map( row => 
          <TableRow >
          <TableCell align="left" sx={{ width: "25%"}}>
            {row.key}
          </TableCell>
          <TableCell align="left">
            <span style={ {color: row.color }}> 
              {row.value} 
            </span>
          </TableCell>
        </TableRow>
        )}
      </TableBody>
    </Table>
  )
}
const DataList = withTranslation()(PureDataList);

export {
  DataList
};