import { withTranslation } from 'react-i18next';
import { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useMutation } from "react-query";

import logger from '../utility/logger.mjs';
import {
  updateTermsConfirm,
  updatePrivacyPolicyConfirm
} from "../server/serverOperation.js";
import { 
  selectLanguage,
  selectTermsAccepted,
  selectPrivacyPolicyAccepted,
  selectUserPhoneId,
  selectLoginType,
  termsAcceptedSet,
  privacyPolicyAcceptedSet
} from "../data/uiSlice.js";
import { selectCurrentPhonenumbers } from '../data/devicesSlice.js';
import { LangaugeSelector } from './LanguageSelector.js';
import terms from "../infos/Terms.js";
import privacyPolicy from "../infos/PrivacyPolicy.js";
import { ButtonWide } from "./Button.js";

import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
} 
from '@mui/material/';

const PureTermsDialog = ({t}) => {
  const dispatch = useDispatch();
  const language = useSelector(selectLanguage);
  const currentPhoneId = useSelector(selectUserPhoneId);
  const superadminUserId = useSelector(selectCurrentPhonenumbers)?.find( num => num.userType === "superadmin" && num.telegramId !== null )?.id
  const loggedWithTelegram = useSelector(selectLoginType) === "telegram";
  const userPhoneId = loggedWithTelegram ? currentPhoneId : superadminUserId;
  //console.log("userPhoneId",userPhoneId);
  const termsAccepted = useSelector(selectTermsAccepted);
  const privacyPolicyAccepted = useSelector(selectPrivacyPolicyAccepted);
  const termsInitialOpen = !termsAccepted && !!superadminUserId;
  const privacyInitialOpen = !termsInitialOpen && !privacyPolicyAccepted && !!superadminUserId;
  const [haveRead, setHaveRead] = useState(false);
  const [termsOpen, setTermsOpen] = useState(termsInitialOpen);
  const [privacyOpen, setPrivacyOpen] = useState(privacyInitialOpen);

  const onTermsOkClick = () => {
    mutateTermsConfirm({ userPhoneId, value: true });
    setHaveRead(false);
    if(!mutateTermsConfirmIsLoading){
      setTermsOpen(false);
    }
    if(!privacyPolicyAccepted && termsOpen){
      setPrivacyOpen(true);
    }
  }

  const onPrivacyPolicyOkClick = () => {
    mutatePrivacyPolicyConfirm({ userPhoneId, value: true });
    setPrivacyOpen(false);
  }

  const { mutate:mutateTermsConfirm, isLoading:mutateTermsConfirmIsLoading, } = useMutation(
    updateTermsConfirm, // parameters {userPhoneId, value}
    {
      onSuccess: async (data) => {
        dispatch(termsAcceptedSet(true));
      },
      onError: (error) => {
        logger.error(error);
      }
    }
  );

  const { mutate:mutatePrivacyPolicyConfirm } = useMutation(
    updatePrivacyPolicyConfirm, // parameters {userPhoneId, value}
    {
      onSuccess: async (data) => {
        dispatch(privacyPolicyAcceptedSet(true));
      },
      onError: (error) => {
        console.log(error);
      }
    }
  );

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (termsOpen || privacyOpen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [termsOpen, privacyOpen]);

  return (
    <>
      <Dialog
        open={termsOpen}
        //onClose={ () => setOpen(false) }
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          {t(terms[language].title)}
          <Box sx={{position: 'absolute', right: 10, top: 13,}}>
            <LangaugeSelector />
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          > 
            <div dangerouslySetInnerHTML={{__html: terms[language].content}} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FormControlLabel 
            checked={haveRead}
            control={
              <Checkbox  onChange={ e => setHaveRead(e.target.checked)} />
              } 
              label={t("have-read")} 
              />
          <ButtonWide 
            margin={0}
            width="26%" 
            text="agree" 
            onClick={ onTermsOkClick }
            disabled={!haveRead}
            />
        </DialogActions>
      </Dialog>

      <Dialog
        open={privacyOpen}
        //onClose={ () => setOpen(false) }
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          {t(privacyPolicy[language].title)}
          <Box sx={{position: 'absolute', right: 10, top: 13,}}>
            <LangaugeSelector />
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          > 
            <div dangerouslySetInnerHTML={{__html: privacyPolicy[language].content}} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <FormControlLabel 
            checked={haveRead}
            control={
              <Checkbox  onChange={ e => setHaveRead(e.target.checked)} />
              } 
              label={t("have-read")} 
              />
          <ButtonWide 
            margin={0}
            width="26%" 
            text="agree" 
            onClick={onPrivacyPolicyOkClick}
            disabled={!haveRead}
            />
        </DialogActions>
      </Dialog>
    </>
  );
}

const TermsDialog = withTranslation()(PureTermsDialog);
export default TermsDialog;