/* eslint-disable no-dupe-keys */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      false: false, // shows nothing
      "dark-mode": "Dark mode on",
      "light-mode": "Dark mode off",
      "header-help-link": "HELP",
      "header-help-link-tooltip": "Open a help page",
      "select-language": "Change language",
      "page-not-found-title": "Page not found!",
      "page-not-found-text": "Please, check your link.",
      "error-alert-title": "Error!",
      "warning-alert-title": "Warning!",
      "info-alert-title": "Info!",
      "success-alert-title": "Success!",
      "token-expired": "Link has expired. Please request new status message and try again.",
      "token-invalid": "Invalid link. Please request new status message and try again.",
      "invalid-url-code": "Invalide QR code. Please try to read the QR code again.",
      "setup-dialog-title": "Device setup",
      "setup-dialog-info-1": "Activate the device by adding a phone number and PIN code for superadmin.",
      "setup-dialog-2-alertinfo": "The phone number and the PIN code added for superadmin.",
      "setup-dialog-info-2": "Please confirm the phone number and activate Main-IoT alarm service in Telegram application.",
      "setup-dialog-info-2-more": "The PIN code can be used along with the QR code activation link (delivered with the device) to change device settings without Telegram access. Notice, if superadmin cannot log in any more with their account and if the PIN code is lost, the phone number of superadmin cannot be changed or unlinked from the device. When logging in with the PIN code you have access to all settings and can set a new superadmin and PIN code. Login page can be accessed by reading the activation QR code delivered with device.",
      "setup-dialog-pin-text": "Create a six-digit PIN code. Keep the code in safe place!",
      "pin-error": "PIN code is too short.",
      "setup-number-error": "Enter a phone number.",
      "activate-bot": "Activate Main-IoT Bot",
      "next": "Next",
      "back": "Back",
      "open": "Open",
      "have-read": "I have read the document.",
      "agree": "Agree",
      "pin-change-info": "The PIN code can be used along with the QR code activation link (delivered with the device) to change device settings without Telegram access.",
      "pin-change-info-more": "Notice, if superadmin cannot log in any more with their account and if the PIN code is lost, the phone number of superadmin cannot be changed or unlinked from the device. When logging in with the PIN code you have access to all settings and can set a new superadmin and PIN code. Login page can be accessed by reading the activation QR code delivered with device.",
      "no-privileges-to-change-pin-text": "Login as superadmin or with the PIN code to change the code.",
      "user-type": "user type",
      "your-privileges": "Your privileges",
      "user": "user",
      "guest": "guest",
      "admin": "admin",
      "superadmin": "superadmin",
      "log-out": "Log out",
      "login": "Log in",
      "enter-pin": "Enter device PIN to log in.",
      "pin-code": "PIN code",
      "pin-incorrect": "Incorrect PIN code.",
      "pin-locked": "Too many failed login attempts. Pin code is locked for a short while.",
      "change-pin": "save PIN",
      "enter-new-pin": "new PIN code",
      "create-new-pin": "create a PIN code",
      "pin-is-saved": "New PIN code saved!",
      "generate-report": "generate report",
      "download-report": "download report",
      "report": "Report",
      "data-report": "Data report",
      "select-range": "Select range",
      "range-start": "Range start",
      "range-end": "Range end",
      "range-start-end-error": "Range must start before it ends.",
      "range-befor-activation-warning": "Range cannot extend before the activation of the device.",
      "get-data": "Get data",
      "device-not-found": "Device not found!",
      "device": "Device",
      "map": "Map",
      "multiguard": "MultiGuard",
      "device-id": "Device ID",
      "search": "Search",
      "device-name": "Device name",
      "device-id": "Device id",
      "id": "id",
      "device-type": "Device type",
      "type": "type",
      "uuid": "UUID",
      "status": "Status",
      "timezone": "Time zone",
      "last-seen": "Last seen",
      "activated": "Activated",
      "device-activated": "Device activated",
      "reference-measured": "Reference value measured",
      "time-left": "Time left",
      "not-found": "not found",
      "no-data": "NO DATA",
      "phonenumber": "Phone number",
      "phonenumbers": "Phone numbers",
      "numbers": "numbers",
      "call-numbers": "alarm call numbers",
      "new-number": "New phone number",
      "warning": "Warning!",
      "error-no-superadmin-selected": "One confirmed phone number must be selected as superadmin or the device will be reseted.",
      "warning-deleting-superadmin-title": "You are reseting the device!",
      "no-superadmin-confirm-warning": "No phone number connected to the Telegram bot is selected as superadmin. If you confirm now, the device will be reseted. In order to use it again it must be initialized with the QR code.",
      "warning-losing-admin-privileges-title": "Downgrading own priviledges",
      "warning-losing-admin-privileges": "Notice that you have downgraded your own user privileges. You cannot undo the change afterwards.",
      "warning-deleting-oneself-title": "Deleting own phone number",
      "warning-deleting-oneself": "Notice that you are deleting your own number! You will be logged out and cannot log in anymore.",
      "warning-at-most-one-superadmin": "The device may have only one superadmin.",
      "phonenumbers-info-inDialog-3": ". After confirmation only the owner can unlink the number from the device, or it must be done by logging in using the PIN code.",
      "phonenumbers-info": `Alarm messages will be send to these phone numbers. Phone numbers marked with a green telephone will get alarm calls. If the background of a number is red, the number is not in use. In that case, please ask the owner of the number to confirm the number with `,
      "with-telegram-bot": "Main-IoT Telegram bot",
      "telegram-bot": "The Telegram bot",
      "edit-numbers": "Edit numbers",
      "insert-phonenumber": "A new phone number",
      "add": "add",
      "clear": "clear",
      "no-phone-numbers-set": "No phone numbers set",
      "undo": "Undo",
      "save": "Save",
      "number-check-ok": "Numbers are checked",
      "check-numbers": "Check numbers",
      "numbers-check-header": "Check numbers",
      "sensitivity-buttons": "Sensor sensitivity",
      "button-off": "Off",
      "button-sensitive": "Sensitive",
      "button-insensitive": "Insensitive",
      "button-sensitive-info-pressure": "Inline.",
      "button-insensitive-info-pressure": "Tank pressure.",
      "send-settings": "Send settings",
      "settings": "Settings",
      "device-info": "Device info",
      "cancel": "Cancel",
      "unit-system": "Unit system",
      "hour-cycle": "Clock",
      "24h": "24-hour",
      "12h": "12-hour",
      "settings-tab": "Settings",
      "advanced-tab": "Advanced",
      "updating-settings-info-text": "Settings will be updated to device within 10 minutes.",
      "data-tab": "Data",
      "store-tab": "Store",
      "devices-tab": "Devices",
      "advanced-settings": "Advanced",
      "three-hours": "3 hours",
      "one-day": "24h",
      "one-week": "a week",
      "one-month": "month",
      "custom-timerange": "custom",
      "from-reference-time": "From reference",
      "reference-time-button": "ref",
      "three-hours-button": "3h",
      "one-day-button": "24h",
      "one-week-button": "1w",
      "one-month-button": "1m",
      "custom-timerange-button": "set",
      "time-range": "Time range",
      "time-range-start": "Start",
      "time-range-end": "End",
      "latest-reboot": "Reference",
      "reference-value": "Reference value",
      "reference-value-abbreviation": "Ref. value",
      "latest-value": "latest value",
      "limit-type": "Alarm limit type",
      "alarm-limits": "Alarm limits",
      "alarm-limit-type-absolute": "Absolute",
      "alarm-limit-type-absolute-info": "User adjustable, fixed alarm limits.",
      "alarm-limit-type-reference-based": "Reference",
      "alarm-limit-type-reference-based-info": "The limits are relative to the reference value.",
      "alarm": "Alarm",
      "yes": "yes",
      "no": "no",
      "off": "off",
      "on": "on",
      "ok": "ok",
      "cancel": "cancel",
      "temperature": "Temperature",
      "humidity": "Humidity",
      "voc": "VOC",
      "co2": "CO2",
      "pir": "PIR",
      "lux": "Lux",
      "spl": "SPL",
      "aaa_general": "General settings",
      "pressure": "Pressure",
      "batteryVoltage": "Battery Voltage",

      // Setting and data labels
      "label-sAmao": "max overshoot",
      "label-sAmad": "max drop",
      "label-sAhlt": "upper limit",
      "label-sAllt": "lower limit",
    }
  },
  fi: {
    translation: {
      false: false, // shows nothing
      "dark-mode": "Tumma tila päällä",
      "light-mode": "Tumma tila pois",
      "header-help-link": "TUKI",
      "header-help-link-tooltip": "Avaa opastussivu",
      "select-language": "Vaihda kieli",
      "page-not-found-title": "Sivua ei löydy!",
      "page-not-found-text": "Tarkista, että linkkisi on oikea.",
      "error-alert-title": "Virhe!",
      "warning-alert-title": "Varoitus!",
      "info-alert-title": "Info!",
      "success-alert-title": "Onnistui!",
      "token-expired": "Linkki on vanhentunut. Pyydä uutta statusviestiä ja yritä uudelleen.",
      "token-invalid": "Linkki ei toimi. Pyydä uutta statusviestiä ja yritä uudelleen.",
      "invalid-url-code": "Virheellinen QR-koodi. Kokeile lukea koodi uudelleen.",
      "setup-dialog-title": "Laitteen käyttöönotto",
      "setup-dialog-info-1": "Aktivoi laite lisäämällä pääkäyttäjän puhelinnumero ja PIN-koodi.",
      "setup-dialog-2-alertinfo": "Puhelinnumero ja PIN-koodi lisätty superadmille.",
      "setup-dialog-info-2": "Varmenna numero ja aktivoi Main-IoT-hälytyspalvelu Telegram-sovelluksessa.",
      "setup-dialog-info-2-more": "PIN-koodin ja laitteen mukana toimitettavan QR-aktivointikoodin avulla laitteen asetuksia voidaan muuttaa ilman kirjautumista Telegramin kautta. Huomaa, että jos pääkäyttäjä ei pääse enää kirjautumaan sisään tunnuksillaan ja jos PIN-koodi on kadonnut, pääkäyttäjän numeroa ei voi vaihtaa tai poistaa laitteelta. Vain kirjautumalla PIN-koodilla tai pääkäyttäjänä, PIN-koodi tai pääkäyttäjän puhelinnnumero voidaan vaihtaa tai poistaa. Kirjautumissivulle pääsee lukemalla laitteen mukana toimitettavan QR-koodin.",
      "setup-dialog-pin-text": "Luo oma kuusinumeroinen PIN-koodisi. Pidä PIN-koodi varmassa tallessa!",
      "pin-error": "PIN-koodi on liian lyhyt.",
      "setup-number-error": "Anna puhelinnumero.",
      "activate-bot": "Aktivoi Main-IoT-botti",
      "next": "Jatka",
      "back": "Takaisin",
      "open": "Avaa",
      "have-read": "Olen lukenut dokumentin.",
      "agree": "Hyväksyn",
      "pin-change-info": "PIN-koodin ja laitteen mukana toimitettavan QR-aktivointikoodin avulla laitteen asetuksia voidaan muuttaa ilman kirjautumista Telegramin kautta.",
      "pin-change-info-more": "Huomaa, että jos pääkäyttäjä ei pääse enää kirjautumaan sisään tunnuksillaan ja jos PIN-koodi on kadonnut, pääkäyttäjän numero ei voi vaihtaa tai poistaa laitteelta. Vain kirjautumalla PIN-koodilla tai pääkäyttäjänä PIN-koodi tai pääkäyttäjän puhelinnnumero voidaan vaihtaa tai poistaa. Kirjautumissivulle pääsee lukemalla laitteen mukana toimitettavan QR-koodin.",
      "no-privileges-to-change-pin-text": "Kirjaudu pääkäyttäjänä tai PIN-koodilla vaihtaaksesi PIN-koodin.",
      "user-type": "käyttäjätyyppi",
      "your-privileges": "Käyttäjäoikeutesi",
      "user": "käyttäjä",
      "guest": "vieras",
      "admin": "ylläpitäjä",
      "superadmin": "pääkäyttäjä",
      "log-out": "Kirjaudu ulos",
      "login": "Kirjaudu",
      "enter-pin": "Syötä laitteen PIN-koodi kirjautuaksesi sisään.",
      "pin-code": "PIN-koodi",
      "pin-incorrect": "Virheellinen PIN-koodi.",
      "pin-locked": "Liian monta epäonnistunutta kirjautumisyritystä. PIN-koodi on lukittu hetkeksi.",
      "change-pin": "tallenna PIN",
      "enter-new-pin": "uusi PIN-koodi",
      "create-new-pin": "keksi PIN-koodi",
      "pin-is-saved": "Uusi PIN-koodi tallennettu laitteelle!",
      "generate-report": "luo raportti",
      "download-report": "lataa raportti",
      "report": "Raportti",
      "data-report": "Dataraportti",
      "select-range": "Valitse tarkasteluväli",
      "range-start": "Välin alku",
      "range-end": "Välin loppu",
      "range-start-end-error": "Aikavälin täytyy alkaa ennen loppua.",
      "range-befor-activation-warning": "Aikavälin oltava laitteen aktivoinnin jälkeisellä ajalla.",
      "get-data": "Hae data",
      "device-not-found": "Laitetta ei löydy!",
      "device": "Laite",
      "map": "Kartta",
      "multiguard": "MultiGuard",
      "device-id": "Laitteen ID",
      "search": "Etsi",
      "device-name": "Laitteen nimi",
      "device-type": "Laitteen tyyppi",
      "type": "tyyppi",
      "device-id": "Laitteen id",
      "id": "id",
      "uuid": "UUID",
      "status": "Status",
      "timezone": "Aikavyöhyke",
      "last-seen": "Viimeksi nähty",
      "activated": "Aktivoitu",
      "device-activated": "Laite aktivoitu",
      "reference-measured": "Referenssiarvo mitattu",
      "time-left": "Aikaa jäljellä",
      "not-found": "ei löydy",
      "no-data": "EI DATAA",
      "phonenumber": "Puhelinnumero",
      "numbers": "numerot",
      "call-numbers": "hälytyssoittonumerot",
      "phonenumbers": "Puhelinnumerot",
      "new-number": "Uusi puhelinnumero",
      "phonenumbers-info-inDialog-1": "Lisää pääkäyttäjän numero laitteelle. Jos alla näkyvä numero on punainen, käyttäjä ei ole varmantanut sitä. Varmennus on tehtävä ",
      "warning": "Varoitus!",
      "error-no-superadmin-selected": "Yksi käyttäjä täytyy olla valittuna superadminiksi.",
      "warning-deleting-superadmin-title": "Olet nollaamassa laitteen!",
      "no-superadmin-confirm-warning": "Superadminia ei ole valittu. Jos hyväksyt valinnat, laite resetoituu eikä sitä voi enää käyttää. Se on otettava uudelleen käyttöön QR-koodin avulla.",
      "warning-losing-admin-privileges-title": "Omien käyttöoikeuksien alentaminen",
      "warning-losing-admin-privileges": "Olet alentamassa omia käyttöoikeuksiasi. Huomaa, ettet voi itse palauttaa niitä myöhemmin.",
      "warning-deleting-oneself-title": "Oman puhelinnumeron poistaminen",
      "warning-deleting-oneself": "Olet poistamassa oman numerosi. Jos tallennat valinnat, sinut kirjataan ulos etkä voi enää kirjautua uudelleen sisään.",
      "warning-at-most-one-superadmin": "Laitteella voi olla vain yksi superadmin.",
      "with-telegram-bot": "Main-IoT Telegram -botilla",
      "phonenumbers-info-inDialog-3": ", ja varmentamisen jälkeen vain pääkäyttäjä voi poistaa oman numeronsa.",
      "phonenumbers-info": "Näihin numeroihin lähetetään hälytysviesti. Jos numeron luuri-ikoni on merkkitty vihreäksi, siihen soitetaan hälytyspuheluita. Numero, jonka taustaväri on punainen, eivät ole käytössä. Pyydä tällöin numeron haltijaa hyväksymään sen käyttö ",
      "telegram-bot": "Telegram-botti",
      "edit-numbers": "Muokkaa numeroita",
      "insert-phonenumber": "Uusi puhelinnnumero",
      "no-phone-numbers-set": "Puhelinnumeroita ei ole asetettu",
      "undo": "Peruuta",
      "send-settings": "Lähetä asetukset",
      "save": "Tallenna",
      "add": "lisää",
      "number-check-ok": "Numerot tarkistettu",
      "check-numbers": "Tarkista numerot",
      "numbers-check-header": "Numeroiden tarkistus",
      "clear": "palauta",
      "sensitivity-buttons": "Anturiherkkyys",
      "button-off": "Pois päältä",
      "button-sensitive": "Herkkä",
      "button-insensitive": "Epäherkkä",
      "button-sensitive-info-pressure": "Kytketty linjaan.",
      "button-insensitive-info-pressure": "Kytketty suoraan kompressoriin.",
      "settings": "Asetukset",
      "device-info": "Laitteen tiedot",
      "cancel": "Peruuta",
      "unit-system": "Yksiköt",
      "hour-cycle": "Kello",
      "24h": "24-tuntinen",
      "12h": "12-tuntinen",
      "settings-tab": "Asetukset",
      "advanced-tab": "Edistynyt",
      "updating-settings-info-text": "Asetukset päivittyvät laitteelle 10 minuutin sisällä.",
      "data-tab": "Data",
      "store-tab": "Kauppa",
      "devices-tab": "Laitteesi",
      "advanced-settings": "Lisäasetukset",
      "three-hours": "3 tuntia",
      "one-day": "vuorokausi",
      "one-week": "viikko",
      "one-month": "kuukausi",
      "custom-timerange": "mukautettu",
      "from-reference-time": "Referenssimittauksesta",
      "reference-time-button": "ref",
      "three-hours-button": "3t",
      "one-day-button": "24t",
      "one-week-button": "1v",
      "one-month-button": "1kk",
      "custom-timerange-button": "valitse",
      "time-range": "Aikaväli",
      "time-range-start": "Alku",
      "time-range-end": "Loppu",
      "latest-reboot": "Referenssi",
      "reference-value": "Referenssiarvo",
      "reference-value-abbreviation": "Ref.-arvo",
      "latest-value": "viimeisin arvo",
      "limit-type": "Hälytysrajatyyppi",
      "alarm-limits": "Hälytysrajat",
      "alarm-limit-type-absolute": "Absoluuttinen",
      "alarm-limit-type-absolute-info": "Käyttäjän asettamat hälytysrajat.",
      "alarm-limit-type-reference-based": "Referenssi",
      "alarm-limit-type-reference-based-info": "Rajat lasketaan suhteellisesti referenssiarvosta.",
      "yes": "kyllä",
      "no": "ei",
      "alarm": "Hälytys",
      "off": "pois",
      "on": "päällä",
      "ok": "ok",
      "cancel": "peru",
      "aaa_general": "Yleisesasetukset",
      "temperature": "Lämpötila",
      "humidity": "Kosteus",
      "voc": "VOC",
      "co2": "CO2",
      "pir": "Liike",
      "lux": "Valo",
      "spl": "Äänenpaine",
      "pressure": "Paine",
      "batteryVoltage": "Akku",

      // Setting and data labels
      "label-sAmao": "maksimiylitys",
      "label-sAmad": "maksimialitus",
      "label-sAhlt": "yläraja",
      "label-sAllt": "alaraja",
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    debug: false,
  });

export default i18n;