import { useState } from "react";

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { withTranslation } from 'react-i18next';

function PureToggleButtons({ t, block }) {
  const disabled = block.disabled ?? false;
  const data = block.data;
  const desc = data.description;
  const orientation = desc?.ORIENTATION;
  const position = desc?.POSITION;
  const labels = desc?.LABELS;
  // console.log("toggleButtonData",data);
  // console.log("o", orientation);
  // console.log("p", position);
  // console.log("l", labels);
  const {newSettings, sentSettings} = block.settingsHandler;
  const valInd = data.JSONValInd;
  const jsonkey =  data.jsonkeys.flat(1);
  const initialValue = newSettings[jsonkey][valInd];
  const [tempValue, setTempValue] = useState(initialValue);

  const handleChange = (event, newValue) => {
    setTempValue(newValue);
  };

  return (
    <ToggleButtonGroup
      disabled={disabled}
      orientation={orientation}
      value={tempValue}
      exclusive
      color="primary"
      onChange={handleChange}
    >
      {labels.map ( (label, i) =>
        <ToggleButton key={label} value={i} >
          {label}
        </ToggleButton>
      )}

    </ToggleButtonGroup>
  );

};

const ToggleButtons = withTranslation()(PureToggleButtons);

export {
  ToggleButtons
};