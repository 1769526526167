import { withTranslation } from 'react-i18next';
import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import { timeRanges as timeRangesFunc } from '../utility/influxChartConfig.mjs';
import { timeRangeSet, selectTimeRange } from '../data/uiSlice.js';
import { selectCurrentDevice } from '../data/devicesSlice.js';
import { DateTimePickerDialog } from './DateTimePickerDialog.js';

import { 
  ToggleButtonGroup, 
  ToggleButton 
} from '@mui/material';

const PureTimeRangeButtons = ({t, sx}) => {
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);

  const device = useSelector(selectCurrentDevice);
  const referenceTime = device.data?.referenceValues?.time;
  const currentTimeRange = useSelector(selectTimeRange);
  const timeRanges = timeRangesFunc(referenceTime);
  const currentTimeRangeIndex = timeRanges.findIndex(range => range.name === currentTimeRange.name);
  const [i, setI] = useState(currentTimeRangeIndex);

  const handleChange = (_, ind) => {
    if(ind == null){
      if( i == 5 ){
        setOpenDialog(true)
      }
    }
    else if(ind < 5) {
      setI(ind);
      dispatch(timeRangeSet(timeRanges[ind]));
      //console.log("timeRangeSet(timeRanges[ind])",timeRanges[ind]);
    }
    else {
      setOpenDialog(true)
    }
  };

  return (
    <>
      <DateTimePickerDialog openDialog={openDialog} setOpenDialog={setOpenDialog} setButtonIndex={() => setI(5)} />
      <ToggleButtonGroup
        size="small"
        color="primary"
        value={i}
        exclusive
        onChange={handleChange}
        sx={{m: "auto", mb: 1, ...sx}}
      >
        <ToggleButton value={4}>{t('reference-time-button')}</ToggleButton> 
        <ToggleButton value={0}>{t('three-hours-button')}</ToggleButton>
        <ToggleButton value={1}>{t('one-day-button')}</ToggleButton>
        <ToggleButton value={2}>{t('one-week-button')}</ToggleButton>
        <ToggleButton value={5}>{t('custom-timerange-button')}</ToggleButton>
      </ToggleButtonGroup>
    </>
  )
}

const TimeRangeButtons = withTranslation()(PureTimeRangeButtons);
export default TimeRangeButtons;