import axios from 'axios'

const fetchDeviceInfoAxios = async (uuid) => {
  const URL = '/api/getDeviceInfo';
  const response = await axios({
    method: 'post',
    url: URL,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application'
    },
    data: JSON.stringify({ deviceUUID: uuid })
  });
  return response;
}

const options = (uuid = "") => {
  return ({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application'
      },
      body: JSON.stringify({ deviceUUID: uuid })
  });
};

const checkToken = async (token) => {
  const URL = '/api/checkToken';  
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({ 
      token,
    })
  });
  const response = await fetch(URL, altOptions);
  return response.json();
};

const deleteToken = async (token) => {
  const URL = '/api/deleteToken';  
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({ 
      token,
    })
  });
  const response = await fetch(URL, altOptions);
  return response.json();
};

const getDevicesByPhonenumber = async (phonenumberId) => {
  if(phonenumberId == null){
    return null;
  }
  const URL = '/api/getDevicesByPhonenumber';  
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({ 
      phonenumberId,
    })
  });
  const response = await fetch(URL, altOptions);
  return response.json();
};

const getDeviceByUUID = async (uuid) => {
  const URL = '/api/getDeviceByUUID';  
  const response = await fetch(URL, options(uuid));
  return response.json();
};

const checkURLcode = async (urlCode) => {
  const URL = '/api/checkURLcode';
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({
      urlCode
    })
  });
  const response = await fetch(URL, altOptions);
  return response.json();
};

const loginWithPin = async (uuid, pin) => {
  const URL = '/api/getLogin/pin';
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({
      deviceUUID: uuid,
      pin
    })
  });
  const response = await fetch(URL, altOptions);
  return response.json();
};

const checkTelegramHash = async (userData) => {
  const URL = '/api/getLogin/checkTelegramHash';
  const altOptions = Object.assign(options(), {
    body: JSON.stringify(userData)
  });
  const response = await fetch(URL, altOptions);
  return response.json();
};

const fetchDeviceInfo = async (uuid) => {
  const URL = '/api/getDeviceInfo';  
  const response = await fetch(URL, options(uuid));
  return response.json();
};

const fetchDeviceInfo2 = async (uuid) => {
  const URL = '/api/getDeviceInfo';  
  const response = await fetch(URL, options(uuid));
  return response;
};

const fetchPhonenumbers2 = async (uuid) => {
  const URL = '/api/getPhonenumbers';
  const response = await fetch(URL, options(uuid));
  return response;
};

const fetchPhonenumbers = async (deviceId) => {
  const URL = '/api/getPhonenumbers';
  const response = await fetch(URL, options(deviceId));
  return response.json();
};

const fetchUiDesc = async (deviceId) => {
  const URL = '/api/getUiDesc';
  const response = await fetch(URL, options(deviceId));
  return response.json();
};

const fetchUiData = async (deviceId, jsonkeys) => {
  const URL = '/api/getUiData';
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({ 
      deviceId,
      jsonkeys
    })
  });
  const response = await fetch(URL, altOptions);
  return response.json();
};

const fetchPendingSettings = async (deviceId, settingsId) => {
  const URL = '/api/getPendingSettings';
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({ 
      deviceId,
      settingsId
    })
  });
  const response = await fetch(URL, altOptions)
  return response.json();
};

const sendNewSettings = async (deviceId, settingsId, settings) => {
  const URL = '/api/sendNewSettings';
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({ 
      deviceId,
      settingsId,
      settings,
    })
  });
  const response = await fetch(URL, altOptions);
  return response;
};

const updateUnitSystem = async ({userPhoneId, unitSystem}) => {
  const URL = '/api/updateUnitSystem';
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({
      userPhoneId,
      unitSystem,
    })
  });
  const response = await fetch(URL, altOptions);
  return response;
};

const updateHourCycle = async ({userPhoneId, hourCycle}) => {
  if(userPhoneId == null || hourCycle == null){
    throw new ReferenceError(`updateHourCycle: userPhoneId ${userPhoneId}, hourCycle ${hourCycle}`);
  }
  const URL = '/api/updateHourCycle';
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({
      userPhoneId,
      hourCycle,
    })
  });
  const response = await fetch(URL, altOptions);
  return response;
};


async function checkPhonenumbers(phonenumbers) {
  const URL = '/api/checkPhonenumbers';
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({
      phonenumbers
    })
  });
  const response = await fetch(URL, altOptions);
  return response.json();
}

async function sendPhonenumber({ deviceId, phonenumber, country, userType, unitSystem, language, hourCycle }) {
  // LISÄÄ altOPTIONS tähän, ks yllä
  await fetch('/api/sendPhonenumber', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application'
    },
    body: JSON.stringify({
      deviceId,
      phonenumber,
      country, 
      userType,
      unitSystem, 
      language, 
      hourCycle
    })
  })
  .then(res => {
    if (!res.ok) { // error coming back from server
      throw Error('could not update the data for that resource');
    } 
    return res.json();
  })
  .then(data => {
    //setIsLoading(false);
  })
  .catch(err => {
    // auto catches network / connection error
    //setIsLoading(false);
    //setError(err.message);
  })
}

async function updateSuperadmin({ deviceId, phonenumber, country, language, unitSystem, hourCycle }) {
  // LISÄÄ altOPTIONS tähän, ks yllä
  await fetch('/api/updateSuperadmin', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application'
    },
    body: JSON.stringify({
      deviceId,
      phonenumber,
      country,
      language,
      unitSystem,
      hourCycle
    })
  })
  .then(res => {
    if (!res.ok) { // error coming back from server
      throw Error('could not update the data for that resource');
    } 
    return res.json();
  })
  .then(data => {
    //setIsLoading(false);
  })
  .catch(err => {
    // auto catches network / connection error
    //setIsLoading(false);
    //setError(err.message);
  })
}

async function updatePhonenumbers({deviceId, phonenumbers}){
  console.log("updatePhonenumbers",deviceId, phonenumbers)
  if(deviceId == null || phonenumbers == null || phonenumbers?.length === 0){
    console.log("parameters unvalid");
    return {error: "parameters unvalid"}
  }
  const URL = '/api/updatePhonenumbers';
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({
      deviceId,
      phonenumbers
    })
  });
  const response = await fetch(URL, altOptions);
  return response.json();
}

async function dropPhonenumber({deviceId, phonenumberId}) {
  // LISÄÄ altOPTIONS tähän, ks yllä
  await fetch('/api/dropPhonenumber', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application'
    },
    body: JSON.stringify({
      deviceId,
      phonenumberId
    })
  })
  .then(res => {
    if (!res.ok) { // error coming back from server
      throw Error('could not update the data for that resource');
    } 
    return res.json();
  })
  .then(data => {
    //setIsLoading(false);
  })
  .catch(err => {
    // auto catches network / connection error
    //setIsLoading(false);
    //setError(err.message);
  })
}

async function dropAllPhonenumbers(deviceId) {
  // LISÄÄ altOPTIONS tähän, ks yllä
  await fetch('/api/dropAllPhonenumbers', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application'
    },
    body: JSON.stringify({
      deviceId
    })
  })
  .then(res => {
    if (!res.ok) { // error coming back from server
      throw Error('could not update the data for that resource');
    } 
    return res.json();
  })
  .then(data => {
    //setIsLoading(false);
  })
  .catch(err => {
    // auto catches network / connection error
    //setIsLoading(false);
    //setError(err.message);
  })
}

const sendNewDeviceName = async ({deviceId, newName}) => {
  const URL = '/api/sendNewDeviceName';
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({ 
      deviceId,
      newName
    })
  });
  const response = await fetch(URL, altOptions);
  return response;
};

const updateLanguage = async (data) => {
  const {userPhoneId, language} = data;
  const URL = '/api/updateLanguage';
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({ 
      userPhoneId,
      language
    })
  });
  const response = await fetch(URL, altOptions);
  return response;
};

const updateTermsConfirm = async ({userPhoneId, value}) => {
  const URL = '/api/updateTermsConfirm';
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({ 
      userPhoneId,
      value
    })
  });
  const response = await fetch(URL, altOptions);
  return response;
};

const updatePrivacyPolicyConfirm = async ({userPhoneId, value}) => {
  const URL = '/api/updatePrivacyPolicyConfirm';
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({ 
      userPhoneId,
      value
    })
  });
  const response = await fetch(URL, altOptions);
  return response;
};

const updatePin = async ({deviceId, newPin}) => {
  const URL = '/api/sendNewPin';
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({ 
      deviceId,
      newPin
    })
  });
  const response = await fetch(URL, altOptions);
  return response;
};

const fetchOnomondoData = async (UUIDs) => {
  const URL = '/api/onomondo';
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({ 
      UUIDs
    })
  });
  const response = await fetch(URL, altOptions);
  return response.json();
};

const fetchInfluxData = async ({bucket, deviceType, deviceUUID, fields}, timeRange, unitSystem, language, hourCycle, offset) => {
  const URL = '/api/getInfluxData';
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({ 
      bucket,
      deviceType,
      deviceUUID,
      fields,
      timeRange,
      unitSystem,
      language,
      hourCycle,
      offset
    })
  });
  const response = await fetch(URL, altOptions);
  return response.json();
};

const fetchKeyValues = async ({bucket, deviceType, deviceUUID, fields}) => {
  const URL = '/api/getKeyValues';
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({ 
      bucket,
      deviceType,
      deviceUUID,
      fields,
    })
  });
  const response = await fetch(URL, altOptions);
  return response.json();
};

const fetchActivationDates = async ({bucket, deviceType, deviceUUIDs}) => {
  const URL = '/api/getActivationDates';
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({ 
      bucket,
      deviceType,
      deviceUUIDs,
    })
  });
  const response = await fetch(URL, altOptions);
  return response.json();
};



export {
  deleteToken,
  checkToken,
  checkURLcode,
  loginWithPin,
  getDeviceByUUID,
  checkTelegramHash,
  getDevicesByPhonenumber,
  fetchDeviceInfo,
  fetchDeviceInfo2,
  fetchDeviceInfoAxios,
  fetchPhonenumbers,
  fetchPhonenumbers2,
  fetchUiDesc,
  fetchUiData,
  fetchPendingSettings,
  checkPhonenumbers,
  updatePhonenumbers,
  sendPhonenumber,
  dropPhonenumber,
  dropAllPhonenumbers,
  updateLanguage,
  updateSuperadmin,
  updateTermsConfirm,
  updatePrivacyPolicyConfirm,
  sendNewSettings,
  sendNewDeviceName,
  updateUnitSystem,
  updateHourCycle,
  updatePin,
  fetchOnomondoData,
  fetchInfluxData,
  fetchKeyValues,
  fetchActivationDates
};