import StarIcon from '@mui/icons-material/Star';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import PersonIcon from '@mui/icons-material/Person';
import VisibilityIcon from '@mui/icons-material/Visibility';

const UserTypeIcon = ({userType, notActive, fontSize}) => {
  const color = (iconColor) => {
    return {color: notActive ? 'grey' : iconColor}
  } 
  switch (userType) {
    case 'superadmin':
      return <StarIcon sx={color('#DAA520')} fontSize={fontSize} />
    case 'admin':
      return  <SupervisorAccountIcon sx={color('#993300')} fontSize={fontSize}/>
    case 'guest':
      return <VisibilityIcon sx={color('grey')} fontSize={fontSize}/>
    case 'user':
    default:
      return <PersonIcon sx={color('#009966')} fontSize={fontSize}/>
  }
}

export default UserTypeIcon;