import { useSelector } from 'react-redux';
import { selectCurrentDevice } from '../data/devicesSlice.js';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import ShowMap from './Map.js';

export default function MapDialog({open, setOpen, position}) {
  const { name, type, uuid } = useSelector(selectCurrentDevice);
  const handleClose = () => setOpen(false);
  return (
    <div>
      <Dialog 
        open={open} 
        onClose={handleClose} 
        fullWidth 
        maxWidth="md" 
      >
        <DialogTitle>Last known location: {name}</DialogTitle>
        <DialogContent>
          <ShowMap 
            position={position} 
            info={{name, type, uuid}}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}