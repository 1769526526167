import { withTranslation } from 'react-i18next';
import {
  Document,
  Page,
  Text,
  StyleSheet,
  Image,
  Font,
  View
} from "@react-pdf/renderer";

const PureChart = ({t, chart}) => {
  return (
    <View wrap={false}>
      <Text style={styles.text}>{t(chart.field).toUpperCase()}</Text>
      <Image style={styles.image} src={chart.image} /> 
    </View>
  )
}
const Chart = withTranslation()(PureChart);

const PureRaportLayout = ({t, data, rebootTime, dateNow, range, charts}) => {
  const name = data?.name;
  const type = data?.type;
  const start = range?.start;
  const end = range?.end;
  //console.log("report",name, type, start, end, rebootTime, charts);
  //console.log("charts.length", charts.length);
  if(charts.length === 0){
    return false;
  }
  return (
    <Document>
      <Page style={styles.body}>
      <Text style={styles.header} fixed>
          Main-IoT
        </Text>
        <Text style={styles.title}>{t("data-report")} {dateNow}</Text>

        <Text style={styles.text}>
          {t("device-name")}: {name}{'\n'}
          {t("device-type")}: {t(type)}{'\n'}
          {'\n'}
          {t("time-range")}{'\n'}
          {t("time-range-start")}: {start}{'\n'}
          {t("time-range-end")}: {end}{'\n'}
          {t("latest-reboot")}: {rebootTime}{'\n'}
        </Text>
          
        { charts.map( chart => <Chart chart={chart} />) }
        
      </Page>
    </Document>
    
);
}
const RaportLayout = withTranslation()(PureRaportLayout);
export { RaportLayout };


const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    //fontFamily: 'Oswald'
  }, 
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    //fontFamily: 'Oswald'
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman'
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});