export const USER_COUNT_MAX = 10;
export const ALARM_CALL_COUNT_MAX = 3;

export const twilioCountries = ['al', 'ad', 'at', 'ba', 'bg', 'cy', 'gr', 'is', 'xk', 'lv', 'li', 'lt', 'lu', 'md', 'mc', 'me', 'pl', 'pt', 'ro', 'sm', 'rs', 'si', 'es', 'ch', 'ua', 'va', 'no','il','fi','cz','dk','ee','nl','be','gb','fr','ie','za','ir','de','sk','om','mt','gp','sg','tw','jp','br','ae','au','us','hu','re','hr','hk','it','se','th'];

export const userTypes = [
  'superadmin',
  'admin',
  'user',
  'guest'
]

export const powerOver = (current, userType) => {
  switch (current) {
    case 'superadmin':
      return true;
    case 'admin':
      return ['admin', 'user', 'guest'].includes(userType);
    case 'user':
    case 'guest':
    default:
      return false;
  }
}

export const phonenumberSortFunc = (n, m) => userTypes.indexOf(n.userType) > userTypes.indexOf(m.userType);

export const countryToUnitSystem = (country) => {
  switch (country) {
    case 'us':
      return 'SAE'; 
    case 'fi':
      return 'SI';
    default: 
      return 'SI';
  };
}

export const countryToHourCycle = (country) => {
  switch (country) {
    case 'au':
    case 'us':
      return 'h12'; 
    default: // 'fi'...
      return 'h23';
  };
}


export const countryToLanguage = (country) => {
  switch (country) {
    case 'fi':
      return 'fi';
    case 'us':
      return 'en';
    default:
      return 'en'; 
  };
} 

export const localesByCountry = (country) => {
  return ({
    language:   countryToLanguage(country),
    hourCycle:  countryToHourCycle(country),
    unitSystem: countryToUnitSystem(country),
  });
};