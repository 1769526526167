export default {
  en: {
    "openButtonText": "Privacy Policy",
    "title": "Privacy Policy",
    "content": `
        <p>Our Privacy Policy was last updated and posted on 8th Sep. 2023. It governs the privacy terms of our Website, located at mobile.main-iot.com. Any capitalized terms not defined in our Privacy Policy, have the meaning as specified in our Terms of Use.</p>

        <ol>
          <li>
            <span>Your Privacy</span>
            <p>Main IoT follows all legal requirements to protect your privacy. Our Privacy Policy is a legal statement that explains how we may collect information from you, how we may share your information, and how you can limit our sharing of your information. You will see terms in our Privacy Policy that are capitalized. These terms have meanings as described in the Definitions section below.</p>
          </li>
      
          <li>
            <span>Definitions</span>
            <p>'Non Personal Information' is information that is not personally identifiable to you and that we automatically collect when you access our Website with a web browser. It may also include publicly available information that is shared between you and others.</p>
            <p>'Personally Identifiable Information' is non-public information that is personally identifiable to you and obtained in order for us to provide you within our Website. Personally Identifiable Information may include information such as your name, email address, and other related information that you provide to us or that we obtain about you.</p>
          </li>
      
          <li>
            <span>Information We Collect</span>
            <p>Registrar</p>
            <p>We collect the content and other information you provide when you use our Services, including when you sign up for an alarm account, create or update device registration. For example relative information for us is a phone number to send alarm messages to.</p>
            <p>If you use our Services for purchases or financial transactions (like when you buy more alarms), we collect information about the purchase or transaction. We are using third-party gateway services for transaction purposes and for that we do not collect any personal information like credit card numbers or person bank account.</p>
            <p>Below is a list of a personal data that may be registered with our Services:</p>
            <p>
              <ul>
                <li>Pressureguard’s descriptive name</li>
                <li>Phone number and Telegram ID</li>
                <li>Email address</li>
                <li>Service purchase and transaction information</li>
              </ul>
            </p>
          </li>
      
          <li>
            <span>Computer Information Collect</span>
            <p>When you use our Website, we automatically collect certain computer information by the interaction of your mobile phone or web browser with our Website. Such information is typically considered Non Personal Information.</p>
          </li>
      
          <li>
            <span>Cookies</span>
            <p>Our Website uses 'Cookies' to identify certain information about customer visited our website. A Cookie is a small piece of data stored on your computer or mobile device by your web browser. We use Cookies to personalize the Content that you see on our Website. Most web browsers can be set to disable the use of Cookies. However, if you disable Cookies, you may not be able to access functionality on our Website correctly or at all. We never place Personally Identifiable Information in Cookies.</p>
          </li>
      
          <li>
            <span>Third Party Tracking Tools</span>
            <p>We also use third party tracking tools to improve the performance and features of our Website. These third party tracking tools are designed to collect only Non-Personal Information about your use of our Website. However, you understand that such tools are created and managed by parties outside our control. As such, we are not responsible for what information is actually captured by such third parties or how such third parties use and protect that information.</p>
          </li>
      
          <li>
            <span>How We Use Your Information</span>
            <p>Customizing Our Website</p>
            <p>We may use the Personally Identifiable information you provide to us along with any computer information we receive to customize our Website.</p>
            <p>Sharing Information with Affiliates and Other Third Parties</p>
            <p>We do not sell, rent, or otherwise provide your Personally Identifiable Information to third parties for marketing purposes. We share your phone number information with third party gateway service to deliver alarm messages and alarm calls.</p>
          </li>
      
          <li>
            <span>Data Aggregation</span>
            <p>We retain the right to collect and use any Non Personal Information collected from your use of our Website and aggregate such data for internal analytics that improve our Website and Service as well as for use or resale to others. At no time is your Personally Identifiable Information included in such data aggregations.</p>
      
          </li>
      
          <li>
            <span>Legally Required Releases of Information</span>
            <p>We may be legally required to disclose your Personally Identifiable Information, if such disclosure is (a) required by subpoena, law, or other legal process; (b) necessary to assist law enforcement officials or government enforcement agencies; (c) necessary to investigate violations of or otherwise enforce our Legal Terms; (d) necessary to protect us from legal action or claims from third parties including you and/or other Members; and/or (e) necessary to protect the legal rights, personal/real property, or personal safety of TermsFeed, our Users, employees, and affiliates. Disclosure of regular data and transfer of data outside of the EU or EEA. No data is disclosed or transferred outside of the EU or EEA.</p>
          </li>
      
          <li>
            <span>Protecting Your Child's Privacy</span>
            <p>Our Website is not designed for use by anyone under the age of 13 ('Child'), though we realize we may have a Child attempt to make purchases through our Website. We do not verify the age of our Users nor do we have any liability for verifying a User's age. If you are a Child, please seek the permission of a parent or guardian before using our Website.If you are a parent or guardian and believe your Child is using our Website, please contact us to remove your Child's account; we reserve the right to ask you for verification of your relationship to the Child before we honor such a request. If we discover that a Child has created an account on our Website, we will immediately delete the account as soon as we discover it, we will not use the information for any purpose, and we will not disclose the information to third parties. However, as parent of such a Child, you understand that you are legally liable for any transactions created by the Child.</p>
      
          </li>
      
          <li>
            <span>Links to Other Websites</span>
            <p>Our Website may contain links to other websites that are not under our direct control. These websites may have their own policies regarding privacy. We have no control of or responsibility for linked websites and provide these links solely for the convenience and information of our visitors. You access such linked Websites at your own risk. These websites are not subject to this Privacy Policy. You should check the privacy policies, if any, of those individual websites to see how the operators of those third-party websites will utilize your personal information. In addition, these websites may contain a link to Websites of our affiliates. The websites of our affiliates are not subject to this Privacy Policy, and you should check their individual privacy policies to see how the operators of such websites will utilize your personal information.</p>
      
          </li>
      
          <li>
            <span>Our Email Policy</span>
            <p>Our affiliates and we fully comply with national laws regarding SPAM. You can always opt out of receipt of further email correspondence from us and/or our affiliates. We agree that we will not sell, rent, or trade your email address to any unaffiliated third-party without your permission.</p>
      
          </li>
      
          <li>
            <span>Privacy Policy Updates</span>
            <p>We reserve the right to modify this Privacy Policy at any time. You should review this Privacy Policy frequently. If we make material changes to this policy, we may notify you on our Website, by email, or by any method we determine. The method we chose is at our sole discretion. We will also change the 'Last Updated' date at the beginning of this Privacy Policy. Any changes we make to our Privacy Policy are effective as of this Last Updated date and replace any prior Privacy Policies.</p>
            <p>Questions About Our Privacy Practices or This Privacy Policy</p>
            <p>If you have any questions about our Privacy Practices or this Policy, please contact us.</p>
          </li>
      
        </ol>
        <p>© 2023 XORTEC OY</p>
      `,
    },

    fi: {
      "openButtonText": "Tietosuojakäytäntö",
      "title": "Tietosuojakäytäntö",
      "content": `
        <p>Tietosuojakäytäntömme on viimeksi päivitetty ja julkaistu 8. maaliskuuta 2018. Siinä määritetään sivustomme yksityisyyden suojaan liittyvät seikat ja ne löytyvät tästä: register.main-iot.fi. Käyttöehdoissa on määritetty niiden isoilla kirjaimilla kirjoitettujen käsitteiden merkitys, joita ei ole määritetty tietosuojakäytännössä.</p>

        <ol>
          <li>
            <span>Yksityisyytesi</span>
            <p>Main IoT noudattaa kaikkia lainsäädännössä asetettuja vaatimuksia yksityisyytesi suojaamiseksi. Tietosuojakäytäntömme on oikeudellinen ilmoitus, jossa kerrotaan kuinka saamme koota sinulta tietoja, kuinka saamme niitä jakaa ja kuinka sinä voit rajoittaa tietojesi jakamista muiden kanssa. Tietosuojakäytännössä on isoilla kirjaimilla kirjoitettuja käsitteitä. Nämä on määritetty alla olevassa Määritelmät-osiossa.</p>
          </li>
      
          <li>
            <span>Määritelmät</span>
            <p>'Muut kuin henkilökohtaiset tiedot' ovat tietoja, joiden perusteella sinua ei pysty tunnistamaan. Keräämme automaattisesti tällaisia tietoja kun siirryt selaimella Sivustollemme. Nämä saattavat sisältää myös julkisesti saatavilla olevaa tietoa, jota jaat muiden kanssa.</p>
            <p>'Henkilötunnistetiedot' ovat ei-julkisia tietoja, joiden perusteella sinut voi tunnistaa. Keräämme niitä voidaksemme palvella sinua sivustollamme. Tällaisia tietoja ovat esimerkiksi nimesi, sähköpostiosoittesi, ja muut sen kaltaiset tiedot, jotka sinä joko annat meille suoraan tai me hankimme muuta kautta.</p>
      
          </li>
      
          <li>
            <span>Keräämämme tiedot</span>
            <p>Rekisterinpitäjä</p>
            <p>Kokoamme tiedot, jotka annat palveluitamme käyttäessäsi, esimerkiksi kun avaat tilin tai silloin kun rekisteröit tai päivität laitteen hälytyspalveluun. Tarpeellisia tietoja ovat muun muassa puhelinnumero ja sähköpostiosoite.</p>
            <p>Jos käytät Palveluitamme ostosten tekemiseen tai transaktioihin (esimerkiksi hälytyksiä ostaessasi), keräämme näihin tapahtumiin liittyviä tietoja. Transaktioissa käytämme ulkoista yhdyskäytävää, joten emme kerää sellaisia tietoja kuin luottokortin tai pankkitilin numerot.</p>
            <p>Alla on lista tiedoista, jotka saatamme tallentaa:</p>
            <p>
              <ul>
                <li>Vuotovahdin kuvaus</li>
                <li>Osoite</li>
                <li>Puhelinnumero</li>
                <li>Sähköposti</li>
                <li>Ostoihin ja transaktioihin liittyvät tiedot</li>
              </ul>
            </p>
          </li>
      
          <li>
            <span>Tietojen kerääminen laitteistasi</span>
            <p>Sivustollamme oleskellessasi keräämme automaattisesti joitakin tietoja käyttämästäsi laitteesta, esimerkiksi matkapuhelimestasi tai selaimestasi. Tällaiset tiedot ovat yleensä Muita kuin henkilökohtaisia tietoja.</p>
          </li>
      
          <li>
            <span>Evästeet</span>
            <p>Käytämme Evästeitä tunnistaaksemme joitakin sivustomme käyttäjiin liittyviä tietoja. Evästeet ovat pieniä tietoja, jotka selaimesi tallentaa tietokoneellesi tai matkapuhelimeesi. Käytämme näitä voidaksemme tarjota sinulle tarpeellista aineistoa sivustollamme. Suurimman osan selaimista voi säätää estämään evästeiden käytön. Mutta jos teet niin, sinulla saattaa olla vaikeuksia käyttää sivustoamme tai sekin on mahdollista, ettet pysty käyttämään sitä ollenkaan. Evästeet eivät koskaan sisällä henkilötunnistetietoja.</p>
          </li>
      
          <li>
            <span>Kolmansien osapuolien jäljitettävyysvälineet</span>
            <p>Käytämme myös ulkopuolisia jäljitettävyysvälineitä tarkoituksenamme parantaa sivustomme toiminnallisuutta ja ominaisuuksia. Nämä välineet on tarkoitettu vain sivustomme käyttöösi liittyvien muiden kuin henkilökohtaisten tietojen keräämiseen. Sinun on kuitenkin huomioitava, että nämä ovat ulkopuolisten luomia ja hallitsemia välineitä ja emme siksi ole vastuussa siitä minkälaista tietoa niillä tosiasiassa kerätään tai siitä kuinka niitä käytetään tai suojataan.</p>
          </li>
      
          <li>
            <span>Kuinka käytämme tietojasi</span>
            <p>Sivuston räätälöimiseksi</p>
            <p>Saatamme käyttää antamiasi henkilötunnistetietojasi räätälöidäksemme sivustomme tarpeiseesi sopivaksi.</p>
            <p>Saatamme jakaa tietojasi Järjestelmän tilaajalle, Yhteistyökumppaneiden ja Kolmansien osapuolien kanssa. Emme myy, vuokraa tai anna millään muulla tavalla henkilötunnistetietojasi kolmansille osapuolille markkinointitarkoituksiin.</p>
            <p>Saatamme antaa henkilötunnistetietosi yhteistyössä oleville palveluiden toimittajille (esimerkiksi maksujen käsittelijöille). Yhteistyökumppanimme saavat vain palveluiden tomittamiseen tarpeelliset tiedot ja niiden käyttö on rajoitettu ja ne on suojattu salassapitovelvollisuuden nojalla.</p>
          </li>
      
          <li>
            <span>Tietojen kerääminen</span>
            <p>Pidätämme oikeuden kerätä ja käyttää kaikkia sivustolla oleskelusi aikana kokoamiamme muita kuin henkilökohtaisia tietojasi sekä sisältää ne sisäisiin analyyseihimme, joita teemme parantaaksemme sivustoamme ja palveluitamme. Pidätämme myös oikeuden antaa ja myydä niitä edelleen. Emme missään tapauksessa käytä henkilötunnistetietojasi näihin tarkoituksiin.</p>
      
          </li>
      
          <li>
            <span>Kun lain nojalla olemme velvollisia paljastamaan tietoja</span>
            <p>Joissakin tapauksissa saatamme olla lain nojalla velvollisia paljastamaan henkilötunnistetietojasi. Näin voi käydä (a) jos eteen tulee haaste ja lain tai oikeudenkäyntimenettelyn niin vaatiessa; (b) jos se on välttämätöntä lainvalvontaviranomaisia tai valtion elimiä autettaessa; (c) jos se on välttämätöntä Oikeudellisten ehtojemme rikkomista tutkittaessa tai niitä valvottaessa; (d) jos se on välttämätöntä puolustautuaksemme oikeudenkäynnissä tai kolmansien osapuolien (esimerkiksi sinun tai muiden Jäsenten) tehdessä reklamaatioita; ja/tai (e) jos se on välttämätöntä TermsFeedin, Käyttäjien, työntekijöiden ja yhteistyökumppaneiden oikeuksien, henkilökohtaisen tai kiinteän omaisuuden ja henkilökohtaisen turvallisuuden suojaamiseksi.</p>
          </li>
      
          <li>
            <span>Lapsesi yksityisyyden suojaaminen</span>
            <p>Meidän sivustoamme ei ole tarkoitettu alle 13-vuotiaiden henkilöiden ('Lasten') käyttöön. Tiedämme kuitenkin, että lapsi voi joskus yrittää ostaa sivustollamme tuotteita tai palveluita. Emme tarkasta käyttäjien ikää emmekä ole velvollisia sitä tekemään. Jos olet lapsi, pyydä vanhemmiltasi tai holhoojaltasi lupa käyttää tätä sivustoa. Jos olet vanhempi tai holhooja ja luulet, että lapsesi käyttää sivustoamme, ota meihin yhteyttä, jotta voimme sulkea lapsen tilin. Pidätämme oikeuden pyytää sinua todistamaan sukulaisuussuhteesi tai laillinen suhteesi lapseen, jotta voimme toteuttaa pyyntösi. Jos saamme selville, että lapsi on avannut meillä tilin, poistamme sen välittömästi. Emme käytä tällaisissa tapauksissa lapsen tietoja mihinkään tarkoitukseen. Emme myöskään paljasta niitä kolmansille osapuolille. Lapsen vanhempana sinun on kuitenkin tiedostettava, että olet laillisesti vastuussa kaikista lapsen tekemistä transaktioista.</p>
      
          </li>
      
          <li>
            <span>Linkit muihin sivustoihin</span>
            <p>Meidän sivustomme saattaa sisältää linkkejä toisiin sivustoihin, joita emme hallitse. Näillä saattaa olla omat tietosuojakäytännöt. Emme hallitse näitä sivustoja emmekä vastaa niistä. Tarjoamme linkit vain mukavuuttasi ajatellen ja myös tiedoksesi. Kun käytät näitä sivustoja, teet sen omalla vastuullasi. Meidän tietosuojakäytäntömme ei ole sovellettavissa näihin ulkopuolisiin sivustoihin. Sinun on syytä tutustua muiden sivustojen mahdollisiin tietosuojakäytäntöihin nähdäksesi kuinka henkilökohtaisia tietojasi niillä käytetään. Nämä sivustot saattavat myös sisältää linkkejä yhteistyökumppaneidemme sivustoihin. Tietosuojakäytäntöämme ei voi soveltaa yhteistyökumppaneidemme sivustohin. Sinun on syytä tarkastaa myös niiden yksityisyydensuojakäytännöt.</p>
      
          </li>
      
          <li>
            <span>Sähköposteihin liittyvä käytäntömme</span>
            <p>Me ja yhteistyökumppanimme noudatamme kaikin puolin ROSKAPOSTIIN liittyviä kansallisia lakeja. Voit milloin tahansa kirjautua ulos meidän ja/tai kumppaniemme sähköpostien jakelulistoilta. Emme myy, vuokraa emmekä vaihda sähköpostiisi liittyviä tietoja ulkopuolisille osapuolille ilman lupaasi.</p>
      
          </li>
      
          <li>
            <span>Tietosuojakäytännön päivitys</span>
            <p>Pidätämme oikeuden muuttaa tätä Tietosuojakäytäntöä milloin tahansa. Sinun on siksi syytä tarkastaa se usein. Jos teemme olennaisia muutoksia, saatamme ilmoittaa sitä sivustollamme tai sähköpostitse, tai muulla valitsemallamme tavalla. Valitsemme muun mahdollisen tavan yksinomaan oman harkintavaltamme puitteissa. Kun muutoksia tehdään, vaihdamme myös tietosuojakäytäntömme alussa olevan 'Viimeksi päivitetty' - päivämäärän. Muutokset astuvat voimaan juuri tuona 'viimeksi päivitetty' -päivänä ja ne mitätöivät edelliset versiot.</p>
            <p>Tietosuojakäytäntöömme liittyvät kysymykset. Jos sinulla on kysyttävää, ota meihin yhteyttä.</p>
            <p>privacy_13_2</p>
          </li>
      
        </ol>
      `,
  },
}