import { useState, useEffect  } from "react";
import { withTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useMutation } from "react-query";

import logger from '../utility/logger.mjs';
import { toLowerCaseFirst } from "../utility/function.mjs";
import { sendNewDeviceName } from "../server/serverOperation.js";
import { currentPageSet } from "../data/uiSlice.js";
import { 
  selectCurrentDevice,
  deviceNameSet,
  selectCurrentUTCzoneOffset,
  selectPrivileges,
} from '../data/devicesSlice.js';
import SettingsList from "../components/SettingsList.js";
// import PhonenumberEditor from "../components/Phonenumbers/PhonenumberEditor2.js";
// import PhonenumberEditDialog from "../components/Phonenumbers/PhonenumberEditDialog.js";
import Phonenumbers from '../components/Phonenumbers/Phonenumbers.js'
import { Timezone } from "../components/PrettyTime.js";
import PinEditor from "../components/PinEditor.js";
import { DataList } from "../components/DataList.js";

import { Box, Typography } from "@mui/material";
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import EditButton from '@mui/icons-material/Edit';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';


const PureDeviceDetails = ( {t} ) => {
  const dispatch = useDispatch();
  const privileges = useSelector(selectPrivileges);
  const offset = useSelector(selectCurrentUTCzoneOffset);
  const disabled = privileges === "guest";
  const {id:deviceId, name, type, uuid} = useSelector(selectCurrentDevice);
  const [showNameEdit, setShowNameEdit] = useState(false);
  const [newName, setNewName] = useState("");
  const [count, setCount] = useState(0);
    
  const handleSaveClick = () => {
    if(newName.length > 0){
      mutate({deviceId, newName})
    }
  }

  const { mutate, isLoading, isSuccess, error } = useMutation(
    sendNewDeviceName, // parameter {deviceId, newName}
    {
      onSuccess: async (data) => {
        dispatch(deviceNameSet({deviceId, newName}));
        setNewName("");
        setShowNameEdit(false);
      },
      onError: (error) => {
        logger.error(error);
      }
    }
  );

  const deviceNameEditButton = () => {
    if(disabled){
      return false;
    }
    return (
      <>
        <IconButton onClick={ () => setShowNameEdit(true) }>
          <EditButton color={"secondary"} fontSize={"small"}/>
        </IconButton>
      </>
    )
  }

  useEffect(() => {
    if( count > 0 ){
      dispatch(deviceNameSet({deviceId, newName}));
      setNewName("");
      setShowNameEdit(false);
    }
  }, [count, dispatch, deviceId, newName]);

  const handleCancelClick = () => {
    setShowNameEdit(false);
    setNewName("");
  }

  const data = [
    [t("type"), t(type)],
    [t("id"), t(uuid)],
    [toLowerCaseFirst(t("timezone")), <Timezone />],
    [t("user-type"), t(privileges)],
  ]

  const devInfo = () => {
    return (
      <Box sx={{width: 260, margin: 'auto'}}>
        <DataList data={data} />
      </Box>
    )
  }

  if(!showNameEdit){
    return (
      <>
        <Typography variant="h2">
          {name} 
          { deviceNameEditButton() }
        </Typography> 
        { devInfo() }
      </>
    )
  }

  return (
    <>
      <Input 
          id="deviceId" 
          type="text" 
          name="devicename"
          placeholder={name}
          onChange={ e => setNewName(e.target.value.trim()) }
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={handleSaveClick} >
                <CheckCircleOutlineOutlinedIcon color="success" fontSize={"small"}/>
              </IconButton>
              <IconButton onClick={handleCancelClick}>
                <CancelOutlinedIcon color="warning" fontSize={"small"}/>
              </IconButton>
            </InputAdornment>
          }
        />
        { devInfo() }
    </>
  )
}
const DeviceDetails = withTranslation()(PureDeviceDetails);

function SubPageMain({ t }) {
  useDispatch()(currentPageSet("MAIN"));
  const disabled = useSelector(selectPrivileges) === "guest";

  return (
    <>
      <Box sx={{mb: 2}}>
        <DeviceDetails />
      </Box>
      <SettingsList />
      <Phonenumbers />
      { !disabled && <PinEditor /> }
    </>
  );
}

 export default withTranslation()(SubPageMain);
