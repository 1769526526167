export default {
  en: {
    "openButtonText": "Terms of service",
    "title": "Terms and Conditions of the Service",
    "content": `
        <p>Read these terms and conditions of the service (“Terms of Use”) carefully before using Xortec Oy’s (“Xortec”) online service. By registering in the online service of the PressureGuard manufactured by Xortec Oy (“Device”), you (“User”) agree to be bound by these Terms of Use. If you do not accept these Terms of Use, you may not use the service.</p>
        <p>Any order or other agreement you may have placed or concluded with Xortec or a distributor shall be hereinafter referred to as an “Agreement” in these Terms of Use. If the Agreement has been concluded with a distributor, the distributor has granted a right of use in accordance with these Terms of Use.</p>
        
        <ol>
          <li>
            <span>Description of the Service </span>
            <p>In these Terms of Use, online services refer to the chargeable online service (“Service”) provided or maintained by Xortec, published on the site [http://www.main-iot.com or http://www.main-iot.fi] and related to the use of the Device. The Service works via a web browser and Telegram application. The Device measures pressure values, temperature values  and collects data on the battery’s voltage values as well as approximate location of the Device. The data is saved on Xortec’s server. Location data is not saved.</p>
            <p>The Service sends a Telegram message of any exceed of user set measurement limits or power outage detected by the Device to maximum ten mobile phone numbers registered by the User and makes a phone call to maximum three preset phone numbers. The duration of the Service has been set out in the Agreement. The Service will not work if the Device has not been registered in the Service or the Device is taken into use without registering a mobile phone number in the Service. The duration of the right of use, as referred to below, however, shall start upon deployment, not the registration of the mobile phone number(s).</p>
            <p>Unless otherwise agreed, a limited right to use the Service is included in the purchase price of the Device. The two-year right of use commences upon deployment as referred to in the instruction manual of the Device and ends after two years have passed since the deployment. An extension for the right to use the Service may be purchased through the Service. The User can also be sent messages via Telegram or email concerning the Service, e.g. reminders concerning the acquisition of an extension of the Service. The message(s) will be sent to the mobile phone numbers or email addresses registered in the Service by the User.</p>
          </li>
          
          <li>
            <span>Deployment of the Service and registration in the Service</span>
            <p>In order to use the Service, the User needs a web browser, a Device and a mobile phone with Telegram application installed in it as well as the required data and mobile phone connections.</p>
            <p>Use of the Service requires the registration of the Device in the Service. In addition to the Device, the User’s mobile phone number(s) will be registered in the Service for sending messages and making automated phone calls. The User can register maximum ten mobile phone numbers per one device, but only three of those numbers can be set to receive automated phone calls.</p>
          </li>
          
          <li>
            <span>User’s right to use the Service</span>
            <p>The User has the right to use the Service in accordance with these Terms of Use and the Agreement.</p>
            <p>The Service is intended for use with the PressureGuard Device manufactured by Xortec. Any other use is prohibited.</p>
            <p>The User is liable for ensuring that the mobile phone numbers submitted to the Service by the User are correct and in use. The User is also responsible for that the User has the right to submit the mobile phone numbers which the User enters in the Service. In addition, the User is liable for ensuring that the Device is within the coverage area of the Service and placed in accordance with the instruction manual. Xortec does not guarantee that the Service functions everywhere within the coverage area. The location of the Device affects signal strength. Signal strength can be weakened or signal blocked e.g. by placement of the Device in a basement, or by a shadow region caused by terrain or other structures blocking the signal.</p>
            <p>The User shall have the right to use the Service for the period of validity set out in the Agreement or section 1 of the Terms of Use, provided that if the User fails to comply with these Terms of Use or the Agreement, Xortec will have the right to block the use of the Service immediately without separate notice. </p>
          </li>
          
          <li>
            <span>Usability of the Service</span>
            <p>Xortec endeavours to ensure uninterrupted and undisrupted availability of the Service. However, Xortec is not responsible for the uninterrupted, timely and error-free operation of the Service.</p>
            <p>Xortec is not liable for damage resulting from interruptions to the Service caused by technical faults, maintenance or installation work, updates, disruptions in information or mobile networks, or any other circumstances.</p>
            <p>Xortec is not liable for damage resulting from the mobile device or the Device being outside the coverage of the Service or otherwise unusable, or from the User failing to take note of a text message or to answer an automated phone call.</p>
            <p>Xortec is not liable for problems with coverage resulting from the placement of the Device.</p>
          </li>
          
          <li>
            <span>Reservation of rights, ownership</span>
            <p>The User is only granted the right of use specifically determined in and granted under these Terms of Use. The granting of the right of use does not constitute a sale, transfer or other assignment of intellectual property rights or proprietary rights owned or used by Xortec to the User.</p>
            <p>All rights to the Service, including copyright and other intellectual property rights, belong to Xortec. Xortec retains all rights which are not specifically granted to the User in these Terms of Use.</p>
            <p>Data collected by the Services belongs to Xortec.</p>
          </li>
          
          <li>
            <span>Liability for the Service</span>
            <p>The Service is provided “as is” without warranty of any kind. Xortec is not liable for the correctness, accuracy or extent of information or other material within the Service.</p>
          </li>
          
          <li>
            <span>Transfer of rights</span>
            <p>The User may not transfer the right of use to a third party without permission from the Company.</p>
            <p>Xortec has the right to transfer the Service or its maintenance or the responsibilities and obligations related thereto to a third party.</p>
          </li>
          
          <li>
            <span>Equipment, software and connections</span>
            <p>The User is liable for the acquisition and functioning of any equipment, software and network connections required in order to use the Service, and any costs resulting from them.</p>
          </li>
          
          <li>
            <span>Prices payable for the Service</span>
            <p>The Service is subject to payment.</p>
            <p>The deployment of the Service requires that the price charged for the Service has been paid before deployment. The payment has been agreed in the Agreement.</p>
          </li>

          <li>
            <span>Refund Policy</span>
            <p>We do not issue refunds for digital products once payment is settled and the order is confirmed.</p>
          </li>

          <li>
            <span>Data protection</span>
            <p>Xortec complies with applicable Finnish data protection legislation when processing the Users’ data. Data is processed as described in the description of the personal data file. The description of the personal data file, which also contains more information on the processing of data, can be accessed online at: mobile.main-iot.com</p>
          </li>
          
          <li>
            <span>Force Majeure</span>
            <p>In the event of Force Majeure, Xortec is released from its obligations related to the Service if the Force Majeure prevents or unreasonably impedes Xortec’s performance related to the Service. Force Majeure events include, inter alia, fire, earthquake, flood, explosion, strike or other stoppage, authorities’ orders, disruption in energy supply, lack of raw materials or other supplies, cable or other data communications disruption caused by or due to external parties, disruption of the mobile network, or any other similar reason which has not been known and against which it has not been reasonably possible to take precautions.</p>
            <p>Xortec will notify of a Force Majeure event on the Service website immediately after it has arisen, if notification is possible.</p>
          </li>
          
          <li>
            <span>Limitation of liability</span>
            <p>TO THE EXTENT PERMITTED BY LAW AND WITH THE EXCEPTION OF ANY WARRANTIES EXPRESSLY AGREED IN THE AGREEMENT AND APPROVED BY XORTEC, XORTEC PROVIDES NO WARRANTIES OR GUARANTEES (EXPRESS OR IMPLIED, WRITTEN OR ORAL) CONCERNING THE SERVICE, INCLUDING BUT NOT LIMITED TO ANY IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE (REGARDLESS OF WHETHER XORTEC IS AWARE OF SUCH PURPOSE OR NOT) OR NON-INFRINGEMENT OF THIRD-PARTY RIGHTS, WHETHER BASED ON LEGISLATION, TRADE PRACTICE IN THE FIELD OR BETWEEN THE PARTIES, OR COURSE OF DEALING.</p>
            <p>UNDER NO CIRCUMSTANCES SHALL XORTEC BE LIABLE FOR ANY SPECIAL, INCIDENTAL, PUNITIVE, CONSEQUENTIAL OR INDIRECT DAMAGES, REGARDLESS OF HOW THEY ARE CAUSED, INCLUDING LOSS OF INCOME OR BUSINESS AND THE USE OF THE SERVICE OR INABILITY TO USE IT, EVEN IF XORTEC HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
            <p>Xortec’s total liability under these Terms of Use is limited to an amount corresponding to the sum actually paid by the User for the Service.</p>
          </li>
          
          <li>
            <span>Changes to the Service and the Terms of Use</span>
            <p>Xortec develops the Service continuously, and it has the right to change the Service and the contents thereof as a part of its development work as and when it deems appropriate.</p>
            <p>Xortec may unilaterally amend these Terms of Use at any time. Any amendments of the Terms of Use will be notified separately as deemed appropriate. The User accepts the amended Terms of Use by using the Service after being informed of the amendments.</p>
          </li>
          
          <li>
            <span>Governing law</span>
            <p>The Service and these Terms of Use shall be governed by the laws of Finland.</p>
          </li>
          
          <li>
            <span>Dispute resolution</span>
            <p>The parties shall endeavour to settle any disputes related to the Service and these Terms of Use primarily through mutual negotiations. If an amicable resolution cannot be reached through negotiations, the disputes shall be taken to the District Court of Helsinki. A consumer has the right to request the Consumer Disputes Board to issue a recommended decision on the dispute.</p>
          </li>
        </ol>
      `,
    },

    fi: {
      "openButtonText": "Käyttöehdot",
      "title": "Palvelun käyttöehdot",
      "content": `
        <p>Lue nämä palvelun käyttöehdot (”Käyttöehdot”) huolellisesti ennen kuin alat käyttää Xortec Oy:n (”Xortec”) verkkopalvelua. Rekisteröimällä Xortec Oy:n valmistaman vesivuotovahdin (”Laite”) verkkopalveluun sinä (”Käyttäjä”) hyväksyt nämä Käyttöehdot sinua sitoviksi. Mikäli et hyväksy näitä Käyttöehtoja, sinulla ei ole oikeutta käyttää palvelua.</p>
        <p>Xortecille tai jälleenmyyjälle tekemääsi tilausta tai muuta heidän kanssa tekemääsi sopimusta kutsutaan näissä Käyttöehdoissa ”Sopimukseksi”. Jos Sopimus on tehty jälleenmyyjän kanssa, on jälleenmyyjä myöntänyt käyttöoikeuden näiden Käyttöehtojen mukaisena.</p>
        
        <ol>
          <li>
            <span>Palvelun kuvaus</span>
            <p>Verkkopalveluilla tarkoitetaan näissä Käyttöehdoissa Xortecin tuottamaa tai ylläpitämää [<a href='http://www.main-iot.com'>http://www.main-iot.com</a> tai <a href='http://www.main-iot.fi'>http://www.main-iot.fi</a>] -sivustolla julkaistavaa Laitteen käyttöön liittyvää maksullista verkkopalvelua (”Palvelu”). Palvelu toimii internet-selaimen kautta. Laite mittaa kosteusarvoja ja kerää pariston jännitearvoja sekä kuuluvuutta Sigfox-verkossa. Tieto tallentuu Xortecin palvelimelle.</p>
            <p>Palvelu ilmoittaa Käyttäjän rekisteröimään matkapuhelinnumeroon tekstiviestillä Laitteen havaitsemasta mahdollisesta vesivahingosta. Jos Käyttäjä ei kuittaa saamaansa tekstiviestiä, Palvelun kautta tulee soitto matkapuhelimeen. Palvelun kesto ja Palvelun piiriin kuuluvien tekstiviestien ja puhelinsoittojen määrä on sovittu Sopimuksessa. Jos muuta ei ole sovittu, on määrä 30 tekstiviestiä tai puhelua kahden vuoden käyttöaikana. Palvelu ei toimi, jos Laitetta ei rekisteröi palveluun tai Laitteen ottaa käyttöön ilman, että Palveluun ei rekisteröi matkapuhelinnumeroa. Jäljempänä mainittu käyttöoikeuden kesto kuitenkin lasketaan käyttöönotosta, ei matkapuhelinnumeron/-numeroiden rekisteröimisestä.</p>
            <p>Ellei muuta ole sovittu, Palvelun käyttöoikeus sisältyy Laitteen ostohintaan. Kahden vuoden käyttöoikeus alkaa Laitteen käyttöohjeen mukaisesta käyttöönotosta ja päättyy kun kaksi vuotta on kulunut käyttöönotosta. Palvelun käyttöoikeus kuitenkin päättyy aina edellä mainitun tekstiviestien ja puheluiden määrän täyttyessä, vaikka käyttöönotosta ei olisikaan kulunut kahta vuotta. Jatkoaikaa palvelun käytölle voi ostaa Palvelun kautta.</p>
          </li>
          
          <li>
            <span>Palvelun käyttöönotto ja siihen rekisteröityminen</span>
            <p>Palvelun käyttäminen edellyttää Käyttäjältä internet-selainta, Laitetta ja matkapuhelinta sekä tarvittavia tietoliikenne- ja matkapuhelinyhteyksiä. </p>
            <p>Palvelu edellyttää Laitteen rekisteröimistä Palveluun. Palveluun rekisteröidään Laitteen lisäksi Käyttäjän matkapuhelinnumero, johon ilmoitus mahdollisesta vesivahingosta tulee. Käyttäjällä on mahdollisuus rekisteröidä enintään kolme matkapuhelinnumeroa.</p>
          </li>
          
          <li>
            <span>Käyttäjän oikeus käyttää Palvelua</span>
            <p>Käyttäjä saa oikeuden käyttää Palvelua näiden Käyttöehtojen ja Sopimuksen mukaisesti.</p>
            <p>Palvelu on tarkoitettu käytettäväksi Xortecin valmistaman Laitteen kanssa ja vain mahdollisesta vesivuodosta ilmoittamiseen. Muu käyttö on kielletty.</p>
            <p>Käyttäjä vastaa siitä, että käyttäjän Palveluun syöttämät matkapuhelinnumerot ovat virheettömiä ja käytössä. Käyttäjä vastaa myös siitä, että Käyttäjällä on oikeus luovuttaa Palveluun syöttämänsä matkapuhelinnumerot. Lisäksi Käyttäjä vastaa siitä, että Laite on palvelun kuuluvuusalueella ja sijoitettu käyttöohjeiden mukaisesti. Palvelun kuuluvuusalueen voi tarkistaa mm. <a href='http://www.main-iot.com'>http://www.main-iot.com</a> tai <a href='http://www.main-iot.fi'>http://www.main-iot.fi</a> -sivustojen kautta. Xortec ei takaa, että Palvelu toimii kaikkialla kuuluvuusalueella. Laitteen sijoituspaikka vaikuttaa kuuluvuuteen. Kuuluvuutta voi heikentää tai sen voi estää esim. Laitteen sijoittaminen kellarikerrokseen, maastosta johtuvat katvealueet tai muut signaalin kulkua estävät rakenteet. Lopullisen kuuluvuuden Laitteen sijoituspaikassa voi tarkistaa Palvelun sivustolla (tarkistuskertojen määrä määritelty Palvelun sivustolla).</p>
            <p>Käyttäjällä on oikeus käyttää Palvelua Sopimuksessa tai Käyttöehtojen kohdassa 1. määritellyn voimassaoloajan edellyttäen kuitenkin, että mikäli Käyttäjä ei noudata näiden Käyttöehtojen tai Sopimuksen ehtoja, on Xortecilla oikeus estää Palvelun käyttö välittömästi ilman erillistä ilmoitusta.</p>
            <p>Xortecilla on myös oikeus estää Palvelun käyttö kun kohdassa 1. mainittu enimmäistekstiviestien/puheluiden määrä tulee täyteen. Käyttäjä voi näissä tilanteissa hankkia käyttöoikeutta lisää erillistä korvausta vastaan.</p>
          </li>
          
          <li>
            <span>Palvelun käytettävyys</span>
            <p>Xortec pyrkii varmistamaan että Palvelu olisi Käyttäjän käytössä jatkuvasti ja häiriöttä. Xortec ei kuitenkaan vastaa Palvelun keskeytyksettömästä, oikea-aikaisesta eikä virheettömästä toiminnasta. </p>
            <p>Xortec ei vastaa vahingoista, jotka johtuvat teknisten vikojen, huolto- tai asennustöiden, päivitysten, tietoliikenne- tai matkapuhelinverkon katkosten tai muiden mahdollisten seikkojen aiheuttamista Palvelun toimintakatkoksista.</p>
            <p>Xortec ei vastaa vahingoista, jotka johtuvat siitä, että Palveluun rekisteröity matkapuhelin tai Laite ei ole Palvelun kuuluvuusalueella, eivät ole muutoin käytettävissä tai Käyttäjä ei huomioi tekstiviestiä tai vastaa hälytyssoittoon.</p>
            <p>Xortec ei vastaa Laitteen sijoittamisesta johtuvista kuuluvuusongelmista.</p>
          </li>
          
          <li>
            <span>Oikeuksien pidättäminen, omistusoikeus</span>
            <p>Käyttäjälle annetaan vain näissä Käyttöehdoissa nimenomaisesti määritelty ja myönnetty käyttöoikeus. Käyttöoikeuden myöntäminen ei tarkoita Xortecin omistamien tai käyttämien immateriaalioikeuksien tai omistusoikeuksien myymistä, siirtämistä tai muuta luovutusta Käyttäjälle.</p>
            <p>Palvelun kaikki oikeudet, tekijänoikeudet ja muut immateriaalioikeudet mukaan lukien, ovat Xortecilla. Xortec pidättää itsellään kaikki oikeudet, joita ei ole nimenomaisesti myönnetty Käyttäjälle näissä Käyttöehdoissa.</p>
            <p>Palvelun keräämä tieto on Xortecin omaisuutta.</p>
          </li>
          
          <li>
            <span>Vastuu Palvelusta</span>
            <p>Palvelu toimitetaan sellaisena kuin se on ilman mitään takuita. Xortec ei ole vastuussa Palvelussa olevan tiedon tai muun materiaalin oikeellisuudesta, täsmällisyydestä tai kattavuudesta.</p>
          </li>
          
          
          <li>
            <span>Oikeuksien siirtäminen</span>
            <p>Käyttäjällä ei ole oikeutta siirtää käyttöoikeutta kolmannelle osapuolelle.</p>
            <p>Xortecilla on oikeus siirtää Palvelu tai sen ylläpito sekä siihen liittyvät vastuut ja velvollisuudet kolmannelle osapuolelle.</p>
          </li>
          
          <li>
            <span>Laitteet, ohjelmistot ja yhteydet</span>
            <p>Käyttäjä vastaa itse Palvelun käyttämisen edellyttämien laitteiden, ohjelmistojen ja verkkoyhteyksien hankkimisesta, toimivuudesta ja niiden aiheuttamista kustannuksista.</p>
          </li>
          
          <li>
            <span>Palvelusta perittävät hinnat</span>
            <p>Palvelu on maksullinen. </p>
            <p>Palvelun käyttöönotto edellyttää, että Palvelusta perittävä hinta on suoritettu ennen käyttöönottoa. Maksusta on sovittu Sopimuksessa.</p>
          </li>

          <li>
            <span>Palautusehdot</span>
            <p>Emme palauta digitaalisten tuotteiden maksuja sen jälkeen, kun maksu on suoritettu ja tilaus on vahvistettu.</p>
          </li>
          
          <li>
            <span>Tietosuoja</span>
            <p>Xortec noudattaa voimassa olevaa Suomen tietosuojalainsäädäntöä käsitellessään Käyttäjien tietoja. Tietoja käsitellään rekisteriselosteessa kuvatulla tavalla. Rekisteriseloste, josta on myös löydettävissä tietojen käsittelyä koskevaa lisätietoa, on luettavissa verkko-osoitteessa: <a href="/fi/privacy">Tietosuojakäytäntö</a></p>
          </li>
          
          <li>
            <span>Ylivoimainen este</span>
            <p>Ylivoimainen este (force majeure) vapauttaa Xortecin Palveluun liittyvistä velvoitteista, mikäli se estää Palveluun liittyvän suorituksen tai kohtuuttomasti haittaa sitä. Ylivoimaisena esteenä pidetään mm. tulipaloa, maanjäristystä, tulvaa, räjähdystä, lakkoa tai muuta työnseisausta, viranomaisen määräystä, energian toimituksessa esiintyviä häiriötä, raaka-aine- tai tarvikepulaa, ulkopuolisen aiheuttamaa tai ulkopuolisesta johtuvaa kaapeli- tai muu tietoliikennehäiriötä, matkapuhelinverson häiriötä tai muuta sen kaltaista syytä, joka ei ole ollut tiedossa ja johon ei ole kohtuudella voitu varautua ennalta.</p>
            <p>Xortec ilmoittaa ylivoimaisesta esteestä Palvelun sivuilla heti sen jälkeen, kun se on ilmennyt, mikäli ilmoittaminen on mahdollista.</p>
          </li>
          
          <li>
            <span>Vastuunrajoitukset</span>
            <p>SOVELLETTAVAN LAIN SALLIMISSA RAJOISSA JA MAHDOLLISIA SOPIMUKSESSA NIMENOMAISESTI SOVITTUJA JA XORTECIN HYVÄKSYMIÄ TAKUITA LUKUUN OTTAMATTA XORTEC EI ANNA MINKÄÄNLAISIA TAKUITA TAI VAKUUTUKSIA (EI NIMENOMAISIA TAI OLETETTUJA EIKÄ KIRJALLISIA TAI SUULLISIA) KOSKIEN PALVELUA, MUKAAN LUKIEN KAIKKI MAHDOLLISET OLETETUT TAKUUT KAUPALLISESTA HYÖDYNNETTÄVYYDESTÄ TAI SOVELTUVUUDESTA TIETTYYN TARKOITUKSEEN (RIIPPUMATTA SIITÄ, ONKO XORTEC TIETOINEN SELLAISESTA TARKOITUKSESTA) TAIKKA KOLMANSIEN OIKEUKSIEN LOUKKAAMATTOMUUDESTA RIIPPUMATTA SIITÄ, PERUSTUVATKO TAKUUT LAINSÄÄDÄNTÖÖN, ALAN TAI OSAPUOLTEN VÄLISEEN KAUPPATAPAAN TAI KÄYTÄNNÖN TOIMINTAAN.</p>
            <p>XORTEC EI MISSÄÄN OLOSUHTEISSA OLE VASTUUSSA MISTÄÄN ERITYISISTÄ, SATUNNAISISTA, RANGAISTUKSEN OMAISISTA, VÄLILLISISTÄ TAI EPÄSUORISTA VAHINGOISTA RIIPPUMATTA SIITÄ, MITEN NE OVAT AIHEUTUNEET, MUKAAN LUKIEN TULONMENETYKSET JA LIIKETOIMINTAMAHDOLLISUUKSIEN HEIKENTYMINEN SEKÄ PALVELUN KÄYTTÖ TAI KYKENEMÄTTÖMYYS KÄYTTÄÄ SITÄ, VAIKKA XORTECILLE OLISI ILMOITETTU TÄLLAISTEN VAHINKOJEN MAHDOLLISUUDESTA.</p>
            <p>Näiden Käyttöehtojen mukainen Xortecin kokonaisvastuu rajoittuu määrään, joka vastaa Palvelusta Käyttäjän tosiasiassa maksamaa summaa.</p>
          </li>
          
          <li>
            <span>Palvelun ja Käyttöehtojen muutokset</span>
            <p>Xortec kehittää Palvelua jatkuvasti ja sillä on oikeus muuttaa Palvelua ja sen sisältöä osana kehitystyötään parhaaksi katsomallaan tavalla ja aikoina.</p>
            <p>Xortecilla on oikeus yksipuolisesti muuttaa näitä Käyttöehtoja milloin tahansa. Käyttöehtojen muutoksista tiedotetaan erikseen sopivaksi katsottavalla tavalla. Käyttäjä hyväksyy muuttuneet käyttöehdot käyttämällä Palvelua sen jälkeen, kun häntä on informoitu muuttuneista käyttöehdoista.</p>
          </li>
          
          <li>
            <span>Sovellettava laki ja riidanratkaisu</span>
            <p>Palveluun ja näihin Käyttöehtoihin sovelletaan Suomen lakia.</p>
          </li>
          
          <li>
            <span>Erimielisyyksien ratkaiseminen</span>
            <p>Palveluun ja näihin käyttöehtoihin liittyvät erimielisyydet pyritään ratkaisemaan ensisijaisesti sopijapuolten välisin neuvotteluin. Mikäli tässä ei onnistuta, ratkaistaan riidat ensiasteena Helsingin käräjäoikeudessa. Kuluttaja-asiakkaalla on oikeus pyytää riitaan kuluttajariitalautakunnan ratkaisusuositusta.</p>
          </li>
        </ol>
        <p>© 2023 XORTEC OY</p>
      `,
  },
}