// timestamp
const ts = () => {
  return new Date().toLocaleString('en-US', { hourCycle: 'h23' });
}

const info = (...params) => {
  console.log(ts(), ...params)
}

const error = (...params) => {
  console.error(ts(), ...params)
}

const mask = (str, num = 3, mask = '*') =>
  ('' + str).slice(0, -num).replace(/./g, mask) + ('' + str).slice(-num);

export default {
  info, error, mask
}