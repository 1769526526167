export function curry2(f) {
  return function(a) {
    return function(b) {
      return f(a, b);
    };
  };
}

export function curry3(f) {
  return function(a) {
    return function(b) {
      return function(c) {
        return f(a, b, c);
      };
    };
  };
}

export const round = (value, decimals = 0) => {
  // if(value == undefined ) {
  //   return null;
  // }
  const factor = 10 ** decimals;
  return Math.round(value * factor) / factor;
};

export const toUpperCaseFirst = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const toLowerCaseFirst = (str) => {
  return str.charAt(0).toLowerCase() + str.slice(1);
}

