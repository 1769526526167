import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import logger from '../utility/logger.mjs';
import useParamSelector from '../data/useParamSelector.js'
import { 
  currentSettingsSet,
  selectComponentValues,
  selectCurrentDevice,
  selectCurrentDeviceIndex, 
  selectCurrentNewSettings,
  selectCurrentComponentDesc
} from '../data/devicesSlice.js';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { withTranslation } from 'react-i18next';


function PureRadioButtons({ t, block }) {
  const dispatch = useDispatch();
  const disabled = block.disabled ?? false;
  const componentId = block.id;
  const data = useParamSelector(selectCurrentComponentDesc, componentId); //useSelector(state => selectCurrentComponentDesc(state, componentId))
  const desc = data.description;
  const orientation = desc?.ORIENTATION;
  const position = desc?.POSITION;
  // LEBEL is an array of label/value pairs: [[label1, value1],[label2, value2],...]
  const labels = desc?.LABELS.map( l => l[0]);
  const buttonValues = desc?.LABELS.map( l => l[1]);
  const title = desc?.TITLE;
  const valInd = data.JSONValInd;
  const jsonkey =  data.jsonkeys.flat(1);
  const currentValue = useParamSelector(selectComponentValues, componentId)[0]; 
  // find the button value that is the last <= the current value sent by the device
  const initialButtonValue = buttonValues.toSorted().filter( value => value <= currentValue ).slice(-1)[0] ?? 0;
  const [buttonValue, setButtonValue] = useState(initialButtonValue)

  const handleChange = (event, newValue) => {
    setButtonValue(parseInt(newValue));
    dispatch(currentSettingsSet({
      values: [parseInt(newValue)],
      jsonkeys: data.jsonkeys,
      valInd
    }));
  };
  
  return (
    <FormControl style={{textAlign: "left", marginLeft: "1em"}}>
      <FormLabel>{t(title)}</FormLabel>
      <RadioGroup
        row={orientation !== "vertical"}
        name="radio-buttons-group"
        value={buttonValue}
        onChange={handleChange}
      >
        {labels.map ( (label, i) =>
          <FormControlLabel key={label} value={buttonValues[i]} control={<Radio disabled={disabled}/>} label={t(label)} />
        )}
      </RadioGroup>
    </FormControl>
  );
};


const RadioButtons = withTranslation()(PureRadioButtons);

export {
  RadioButtons
};