import { withTranslation } from 'react-i18next';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useParamSelector from '../data/useParamSelector.js'
import { 
  currentSettingsSet,
  selectComponentValues,
  selectCurrentDevice,
  selectCurrentDeviceIndex, 
  selectCurrentNewSettings,
  selectCurrentComponentDesc
} from '../data/devicesSlice.js';

import { 
  Box,
  FormControl,
  FormLabel,
  MenuItem,
  Select as SelectMUI
} from "@mui/material";

function PureSelect({ t, block }) {
  const dispatch = useDispatch();
  const disabled = block.disabled ?? false;
  const componentId = block.id;
  const data = useParamSelector(selectCurrentComponentDesc, componentId); //useSelector(state => selectCurrentComponentDesc(state, componentId))
  //console.log("radiobutton data",data);
  const desc = data.description;
  // LEBEL is an array of label/value pairs (or triple with infotext): [[label1, value1(, infotext)],[label2, value2(, infotext)],...]
  const labels = desc?.LABELS.map( l => l[0]);
  const buttonValues = desc?.LABELS.map( l => l[1]);
  const valueInfos = desc?.LABELS.map( l => l?.[2] ?? false);
  const title = desc?.TITLE;
  //console.log("radioButtonData",data);
  const currentValue = useParamSelector(selectComponentValues, componentId)[0];
  // find the button value that is the last <= the current value sent by the device
  const initialButtonValue = buttonValues.toSorted().filter( value => value <= currentValue ).slice(-1)[0] ?? 0;
  const [buttonValue, setButtonValue] = useState(initialButtonValue)

  const handleChange = (event) => {
    //console.log("select newValue", event.target.value);
    setButtonValue(parseInt(event.target.value));
    dispatch(currentSettingsSet({
      values: [parseInt(event.target.value)],
      jsonkeys: data.jsonkeys,
      valInd: data.JSONValInd
    }));
  };

  // labels.map ( (label, i) => {
  //   console.log("label",desc?.LABELS,label,i);
  // });
  
  return (
    <Box sx={{display: "flex", clear:"both"}}>
      <FormControl size="small" sx={{textAlign: "left", marginLeft: "1em", minWidth: 120}}>
        <FormLabel>{t(title)}</FormLabel>
        <SelectMUI
          name="select"
          value={buttonValue}
          onChange={handleChange}
          sx={{marginTop:".5em"}}
        >
          {labels.map ( (label, i) =>
            <MenuItem key={label} value={buttonValues[i]}>{t(label)}</MenuItem> 
          )}
        </SelectMUI>
      </FormControl>
      <Box sx={{marginTop: "3em", marginLeft: 2, fontSize: "small"}}>
        {t(valueInfos[buttonValues.indexOf(buttonValue)])}
      </Box>
    </Box>
  );
};


const Select = withTranslation()(PureSelect);

export {
  Select
};