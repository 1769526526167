import { useState } from "react"

import { 
  Collapse, 
  IconButton 
} from "@mui/material";
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'; 
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';

const HelpTextExpand = styled((props) => {
  const { expand, ...other } = props;
  return ( 
    <>
      <IconButton {...other}>
        <InfoIcon sx={{color: "secondary.main"}}  /> 
      </IconButton>
    </>
  );
})(({ theme, expand }) => ({
  color: "#4d8dc1",
  //transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)', 
  //marginLeft: 'auto',
  //transition: theme.transitions.create('transform', {
  //  duration: theme.transitions.duration.shortest,
  //}),
}));

export const HelpText = ({text, buttonStyle}) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  
  return (
    <>
      <HelpTextExpand
          style={buttonStyle}
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
      </HelpTextExpand>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <span style={{fontSize: "small"}}> {text}</span>
        <IconButton
            aria-label="expand row"
            size="small"
            onClick={ handleExpandClick }
          >
            <KeyboardArrowUpIcon />
          </IconButton>
      </Collapse>
    </>
  )
}


const ReadMoreExpand = styled((props) => {
  const { expand, ...other } = props;
  return ( 
    <>
      <IconButton {...other}>
        <InfoIcon  /> 
      </IconButton>
    </>
  );
})(({ theme, expand }) => ({
  color: "#4d8dc1",
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)', 
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
   duration: theme.transitions.duration.shortest,
  }),
}));

export const ReadMore = ({text, buttonStyle}) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  
  return (
    <>
      <ReadMoreExpand
          style={buttonStyle}
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
      </ReadMoreExpand>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <span style={{fontSize: "small"}}> {text}</span>
        <IconButton
            aria-label="expand row"
            size="small"
            onClick={ handleExpandClick }
          >
            <KeyboardArrowUpIcon />
          </IconButton>
      </Collapse>
    </>
  )
}