import { useState } from "react";
import { withTranslation } from 'react-i18next';
import { useSelector, useDispatch } from "react-redux";
import { toast } from 'react-toastify';

import { selectCurrentDeviceId, selectCurrentPage } from '../data/uiSlice.js';
import { 
  showAdvancedSet, 
  newSettingsRestored, 
  sentSettings, 
  currentNewSettingsRestored,
  selectCurrentActivationTime,
  //selectCurrentSettingsToBeSent,
  selectCurrentSettingsState,
  selectCurrentSettingsSentTimeStamp,
  selectPrivileges,
  selectCurrentDeviceIndex,
  //settingsToBeSentReseted,
  settingsSentTimeStampSet,
  selectCurrentNewSettings
} from '../data/devicesSlice.js';
import { AlertInfo } from "./Alert.js";
import { SettingsComponent } from "./SettingsComponent.js";
import { ButtonWide } from "./Button.js";

import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  FormControlLabel,
  Switch,
  Divider,
  Typography, 
} from '@mui/material';

const PureSettingsList = ({t}) => {
  const dispatch = useDispatch();
  const status = useSelector( state => state.status);
  const pagename = useSelector(selectCurrentPage);
  const privileges = useSelector(selectPrivileges);
  const disabled = privileges === "guest";
  const settings = useSelector(selectCurrentNewSettings);
  const settingsState = useSelector(selectCurrentSettingsState);
  const settingsSentTimeStamp = useSelector(selectCurrentSettingsSentTimeStamp);
  const deviceId = useSelector(selectCurrentDeviceId);
  const deviceIndex = useSelector(selectCurrentDeviceIndex);
  const desc = useSelector( state => state.devices.device[deviceIndex].uiDesc ).filter( row => row.uiPage === pagename );
  const influxKeys = [...[...(new Set(desc.map((row) => row.influxKey)))].toSorted()];
  const jsonkeys = [...(new Set(desc.map( (row) => row.jsonkeys).flat(1)))];
  const [showCategories, setShowCategories] = useState(influxKeys);
  const contentList = [...influxKeys.map( (influxKey) => ({
        category: influxKey, 
        settingsList: desc
          .filter( row => row.influxKey === influxKey )
          // filter the components by "hide if" conditions
          .filter( row => {
                      if(row.hideIf == null){
                        return true;
                      }
                      if(row.hideIf?.always){
                        return false;
                      }
                      return Object.keys(row.hideIf).reduce( (acc, key) => acc && settings?.[key]?.[row.hideIf[key].valInd === "VAL_IND" ? row.JSONValInd : row.valInd] === row.hideIf[key].value, true);
                    })
          .sort( (a,b) => a.weight - b.weight )
      }
    ))
  ]; 
  //console.log("contentList",desc,contentList,settings);
  const isAdvancedSetting = contentList.reduce( (a, row) => ({...a, [row.category]: [... row.settingsList.map( component => component.advanced )]}), {});
  const [showAdvanced, setShowAdvanced] = useState(useSelector((state) => state.devices.device[deviceIndex].data.showAdvanced));

  // const settingSentInfo = () => {
  //   if(settingsState == "error"){
  //     <AlertInfo title={""} text="error-senting-settings"/>
  //   }
  //   const diley = 600000; // milliseconds = 10 mim
  //   const now = Date.now();
  //   //console.log("settingsinfo", diley, Date.now(), settingsSentTimeStamp, !!settingsSentTimeStamp, now - settingsSentTimeStamp, (now - settingsSentTimeStamp) < diley);
  //   if((now - settingsSentTimeStamp) < diley){
  //       return <AlertInfo title={""} text="updating-settings-info-text"/>
  //   }
  //   return false;
  // }

  const handleShowAdvanced = ( category, value ) => {
    setShowAdvanced({
      ... showAdvanced,
      [category]: value
    });
    dispatch(showAdvancedSet({
      id: deviceId,
      category,
      value
    }))
    //console.log("showAdvanced",showAdvanced);
  }

  const handleCategory = ( event, newCategories ) => {
    setShowCategories(newCategories);
  };

  const saveSettings = () => {
    dispatch(sentSettings(deviceId));
    //dispatch(settingsToBeSentReseted(deviceId));
    dispatch(settingsSentTimeStampSet({deviceId, timeStamp: Date.now()}));
    toast.info(<AlertInfo title={""} text="updating-settings-info-text"/>, {
      toastId: "updating-settings-info"
    });
  }

  // <ToggleButtonGroup
  //       value={showCategories} 
  //       color="primary"
  //       onChange={handleCategory}
  //     >
  //       {influxKeys.map ( (influxKey) =>
  //         <ToggleButton key={influxKey} value={influxKey}>
  //           {t(influxKey + "-abbreviation")}
  //         </ToggleButton>
  //       )}
  //     </ToggleButtonGroup>

  return (
    <Box sx={{ margin: "0.5em 0 0 0"}}> 
      {contentList.map( (r, rIndex) => (
          <Box 
            key={r.category}
            style={{ 
              margin: "0", 
              padding: "0 0 0.5em",
              textAlign: "left",
              //backgroundColor: (alarmsAreOn ? "" :"#FFF0F0"),
              // show only selected categories
              display: showCategories.includes(r.category) ? "block" : "none"
            }}
          >
            <div style={{display: "flex", alignItems: "center", clear:"both",  justifyContent: "space-between"}}>
              <Typography variant="h2" style={{margin: "0" }}>{t(r.category)}</Typography>
              <FormControlLabel
                labelPlacement="start"
                control=
                  {<Switch
                    key={`${r.category}-advanced`}
                    checked={showAdvanced[r.category]}
                    style={{ margin: "0"}}
                    onChange={( e, value) => handleShowAdvanced(r.category, value)}
                  />} 
                label={t("advanced-settings")}
                style={{display: isAdvancedSetting[r.category].includes(1) ? "block" : "none"}}
              />
          </div>
          <div style={{margin: "0", padding: 0}}>
            {r.settingsList.map( (row, i) => {
                const hide = !showAdvanced[r.category] && isAdvancedSetting[r.category][i]
                return (
                  <div style={{textAlign: "left", margin: hide ? 0 : ".5em 0 0",}} key={row.id}>
                  {SettingsComponent({
                    id: row.id,
                    _uid: r.category + jsonkeys.toString() + row.type, 
                    component: row.type,
                    hide,
                    disabled
                  })}
                </div>
                )
              }
            )}
          </div>
            {/* &nbsp;  !!hideComponent[r.category][i] ? <div>&nbsp;</div> :
            <Stack direction="row" spacing={1} alignItems="center">
            <p style={{textAlign: "left"}}>Send alarms with </p>
                <FormControlLabel control={<Checkbox disabled={!alarmsAreOn} defaultChecked />} label="Telegram" />
                <FormControlLabel control={<Checkbox disabled={!alarmsAreOn} />} label="phonecall" />
            </Stack> */}
            <Divider sx={{ mt: 5, mb: 2, clear:"both" }}/>
          </Box>
        )
      )} 
      { /* hide buttons if 0 categories is shown or the user is a guest */
        (!!showCategories.length && privileges !== "guest" ) && 
      <>  
        {/* <ButtonWide width="half" text="undo" handleclick={restoreSettings} /> */}
        <ButtonWide 
          width="half" 
          text="send-settings" 
          onClick = { saveSettings } 
          isLoading = { status == "loading" }
          disabled = { disabled || settingsState == "notChanged" }
        />
        {/* { settingSentInfo() } */}
      </>}
      {/* {!!settingsArePending && 
        <p>settingsArePending ....</p>} */}
    </Box>
  );
};

const SettingsList = withTranslation()(PureSettingsList);
export default SettingsList;