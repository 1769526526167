import { DateTime } from 'luxon';

export const formatTime = (timeStr, format='DATETIME_SHORT', language='en', hourCycle='h12', offset=0) => {
  let formatOptions = {};
  switch (format) {
    case 'onlyTime':
    case 'three-hours':         // timeRange.name
      formatOptions =  DateTime.TIME_SIMPLE;
      break;
    case 'timeDay':
    case 'one-day':             // timeRange.name
    case 'one-week':            // timeRange.name
    case 'from-reference-time': // timeRange.name
    case 'custom-timerange':    // timeRange.name
      const {year, ...preformatingWihtOutYear} = DateTime.DATETIME_SHORT;
      formatOptions = {...preformatingWihtOutYear,  weekday: 'short'};
      break;
    case 'onlyDate':
    case 'one-month':           // timeRange.name
      formatOptions = DateTime.DATE_SHORT;
      break;
    default:
      formatOptions = DateTime[format];
      break;
  }

  return DateTime
    .fromISO(timeStr)
    .toUTC(offset)
    .setLocale(language)
    .toLocaleString({...formatOptions, hourCycle});
}
