import { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from "react-query";

import logger from '../utility/logger.mjs';
import { timeRanges } from '../utility/influxChartConfig.mjs';
import {
  fetchPhonenumbers,
  fetchUiDesc,
  fetchUiData,
  fetchPendingSettings, 
  fetchKeyValues,
} from "../server/serverOperation.js";
import { 
  deviceAdded, 
  selectCurrentDevice, 
  selectCurrentDataIsLoaded,
  selectCurrentActivationTime,
} from '../data/devicesSlice.js';
import { timeRangeSet } from '../data/uiSlice.js';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CircularProgress from '@mui/material/CircularProgress';

import SubPageMain from './SubPageMain.js';
//import SubPageAdvanced from './SubPageAdvanced';
import SubPageData from './SubPageData.js';
//import SubPageStore from './SubPageStore';
import SubPageDevices from './SubPageDevices.js';
// import { SettingsBackupRestore } from '@mui/icons-material';
import SetupDialog from "../components/SetupDialog.js";
import TermsDialog from '../components/TermsDialog.js';

const subPages = [
  SubPageData,
  SubPageMain,
  SubPageDevices,
  //SubPageAdvanced,
  //SubPageStore
];

const Settings = ({t, openPage=0}) => {
  const PUBLIC_VERSION = !!Number(process.env.REACT_APP_IS_PUBLIC_VERSION);
  const dispatch = useDispatch();
  const activationTime = useSelector(selectCurrentActivationTime);
  const dataIsLoaded = useSelector(selectCurrentDataIsLoaded);
  const device = useSelector(selectCurrentDevice);
  const deviceData = device;
  const deviceId = device.id;
  const [value, setValue] = useState(openPage);

  const { data:phonenumbers, status:status2 } = useQuery(
    ["phonenumbers", deviceId],
    () => fetchPhonenumbers(deviceId),
    { enabled: !dataIsLoaded }
  );
  const { data:uiDesc, status:status3 } = useQuery(
    ["uiDesc", deviceId],
    () => fetchUiDesc(deviceId),
    { enabled: !dataIsLoaded }
  ); 
  //console.log("uiDesc", uiDesc);
  const { data:uiData, status:status4 } = useQuery(
    ["uiData", deviceId],
    () => { 
      const jsonkeys = [... new Set(uiDesc.map((row) => JSON.parse(row.jsonkeys)).flat(1))].filter(n => n);
      return fetchUiData(deviceId, jsonkeys);
    },
    { enabled: !!uiDesc }
  );
  //console.log("uiData", uiData);

  const { data:pendingSettings, status:status5, isSuccess:isSuccess5 } = useQuery(
    ["pendingSettings", deviceId],
    () => { 
      const settingsId = Math.max(...uiData.map( row => row.settingsId));
      return fetchPendingSettings(deviceId, settingsId);
    },
    { enabled: !!uiData }
  );
  const { data:keyValues, status:status6, isSuccess:isSuccess6, isError:keyValuesIsError } = useQuery(
    ["keyValues", deviceId],
    () => {
      if(activationTime == null){
        throw Error("device not activated: key values not fetched");
      }
      const queryParams = {
        bucket: 'DeviceData',
        deviceType: device.type,
        deviceUUID: device.uuid,
        fields: [...new Set(uiDesc.map(row => row.influxKey).filter(n => n))],
      }
      return fetchKeyValues(queryParams);
    },
    { enabled: !!uiDesc }
  );
  
  // parse json strings and construct the data object 
  const data = {
    ...deviceData,
    phonenumbers,
    settings: uiData?.reduce((acc, cur) => ({ ...acc, [cur.jsonkey]: JSON.parse(cur.value)}), {}), // parse value arrays
    pendingSettings: (pendingSettings?.length === 0) ? null : pendingSettings, // set null if array is []
    settingsId: uiData?.reduce((acc, cur) => ((acc > cur.settingsId) ? acc : cur.settingsId), 0), // max settingsId
    settingsTime: uiData?.[0]?.timestamp,
    uiDesc: uiDesc
      ?.filter(row => !!Number(row.dev) ? !PUBLIC_VERSION : true) // filter dev version elements if public version
      .map( (row) => ( {
        ...row, 
        description: Object.assign({}, JSON.parse(row?.description), JSON.parse(row?.devElemDesc)),
        jsonkeys: JSON.parse(row?.jsonkeys)?.flat(1),
        influxKey: row?.influxKey ?? "aaa_general",
        hideIf: JSON.parse(row?.hideIf),
      })),
    data: keyValues != null ? {...keyValues} : {},
  };

  if(isSuccess5 && (status6 === "success" || status6 === "error") && !dataIsLoaded){
    dispatch(deviceAdded(data));
    dispatch(timeRangeSet(timeRanges(data?.data?.referenceValues?.time ?? "-1d")[4]));
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabSelector(props) {
    const SelectedTab = subPages[props.value];
    return  <SelectedTab selectSubPage={props.selectSubPage}/>;
  };

  const SetupDialogs = () => {
    if(dataIsLoaded){
      return (
        <>
            <SetupDialog />
            <TermsDialog /> 
        </>
      )
    }
    return false;
  }

  const SupPageTabs = () => {
    return (
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs 
          value={value} 
          onChange={handleChange} 
          //variant="scrollable"  // jos tabeja on useita, tarvinnee voida skrollata. Jos ei, niin olis kiva keskittää. Ei voi molempia.
          scrollButtons="auto" 
          allowScrollButtonsMobile
          centered >
            <Tab label={t('data-tab')} />
            {/* <Tab label={t('advanced-tab')} /> */}
            <Tab label={t('settings-tab')} />
            <Tab label={t('devices-tab')} />
            {/* <Tab label={t('store-tab')} /> */}
        </Tabs>
      </Box>
    )
  }

  const Content = () => {
    if((status6 === "success" || status6 === "error") && isSuccess5 ){
      return <TabSelector value={value} selectSubPage={setValue} />
    }
    return <CircularProgress />
  }

  return (
    <>
      <SetupDialogs />
      <SupPageTabs />
      <Box sx={{m:3}}>
        <Content />
      </Box>
    </>
  )
}



export default withTranslation()(Settings);