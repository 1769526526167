import { 
  createSlice, 
  createAsyncThunk, 
  createSelector, 
} from "@reduxjs/toolkit";
import { phonenumberSortFunc } from "../utility/userConfig.mjs";
import { selectCurrentDeviceId } from './uiSlice.js';
import {
  fetchDeviceInfoAxios,
  fetchPhonenumbers2,
  sendNewSettings
} from "../server/serverOperation.js";

const initialState = { 
  device: [],
  test: [],
  status: 'idle', // 'idle' | 'loading' | 'succeed' | 'failed'
  error: null
};

export const currentSettingsSet = createAsyncThunk(
  'devices/currentSettingsSet',
  async ({values, jsonkeys, valInd }, { getState }) => {
    const deviceId = getState().ui.currentDeviceId;
    const i = getState().devices.device.findIndex( d => d.id === deviceId );
    const newSettings = formatAlteredSettings(
      getState().devices.device[i].newSettings, 
      jsonkeys, 
      valInd, 
      values
    );
    return { i, newSettings, valInd, jsonkeys };
  }
);

export const currentNewSettingsRestored = createAsyncThunk(
  'devices/currentNewSettingsRestored',
  async (_, { getState }) => {
    const deviceId = getState().ui.currentDeviceId;
    const i = getState().devices.device.findIndex( d => d.id === deviceId );
    return { i };
  }
);

export const currentPhonenumbersSet = createAsyncThunk(
  'devices/currentPhonenumbersSet',
  async ({newPhonenumbers}, { getState }) => {
    const deviceId = getState().ui.currentDeviceId;
    const i = getState().devices.device.findIndex( d => d.id === deviceId );
    return { i, newPhonenumbers };
  }
);

export const getDeviceData = createAsyncThunk(
  'devices/getDeviceData', 
  async (deviceUUID) => {
    const res = await fetchDeviceInfoAxios(deviceUUID);
    return res;
  }
);

export const getPhoneNumbers = createAsyncThunk(
  'devices/getPhoneNumbers', 
  async (deviceUUID) => {
    const res = await fetchPhonenumbers2(deviceUUID);
    return res;
  }
);

export const sentSettings = createAsyncThunk(
  'devices/sendNewSettings', 
  async ( deviceId, { getState, rejectWithValue } ) => {
    const i = getState().devices.device.findIndex( d => d.id === deviceId );
    const newSettingsId = getState().devices.device[i].settingsId + 1;
    const settings = getState().devices.device[i].newSettings;
    if(JSON.stringify(settings) === '{}'){
      rejectWithValue("newSettings empty")
    }
    try{
      const res = await sendNewSettings(deviceId, newSettingsId, settings);
      return {i, data: res.data};
    }
    catch (err) {
      if (!err.res) {
        throw err
      }
      return rejectWithValue({i, error: err.res.data})
    }
  }
);

const formatAlteredSettings = (oldSettings, jsonkeys, valInd, values) => {
  const alteredSettings = Object.assign({}, ...jsonkeys.map( (jsonkey, i) => (
    {
      [jsonkey]: Object.assign([], oldSettings[jsonkey], {[valInd]: values[i]})
    }
  )));
  return alteredSettings;
}

export const deviceSlice = createSlice({
    name: 'device',
    initialState,
    reducers: {
      deviceAdded: (state, action) => {
        let deviceInd = state.device.findIndex( d => d.id === action.payload.id );
        deviceInd = deviceInd === -1 ? 0 : deviceInd;
          state.device[deviceInd] = {
              id:               action.payload?.id,
              name:             action.payload?.name,
              type:             action.payload?.type,
              typeId:           action.payload?.typeId,
              uuid:             action.payload?.uuid,
              phonenumbers:     action.payload?.phonenumbers?.toSorted(phonenumberSortFunc),
              settings:         action.payload?.settings,
              settingsTime:     action.payload?.settingsTime,
              newSettings:      action.payload?.settings,
              pendingSettings:  action.payload?.pendingSettings,
              // settingsToBeSent: {},
              settingsSentTime: null, 
              settingsId:       action.payload?.settingsId,
              uiDesc:           action.payload?.uiDesc,
              data:             action.payload?.data, //|| {referenceValues: {}, showAdvanced: {}},
              privileges:       action.payload?.privileges ?? "user",
              dataIsLoaded:     true,
              settingsState:    "notChanged", // "changed" | "notChanged" | "pending" | "error"
              simid:            action.payload?.simid,
              activationTime:   action.payload?.activationTime,
              country:          action.payload?.country,
              utcZoneOffset:    action.payload?.utcZoneOffset,
              deviceStatus:     action.payload?.deviceStatus,
              brand:     action.payload?.brand,
          };
          if(state.device[deviceInd].uiDesc) {
            const influxKeys = [...(new Set(state.device[deviceInd].uiDesc.map((row) => row.influxKey)))];
            state.device[deviceInd].data.showAdvanced = Object.fromEntries(influxKeys.map(k => [k, false]));
          };
      },
      devicesAdded: (state, action) => {
        state.device = action.payload;
      },
      // action.payload == { deviceId, jsonkeys, jsonValInd, newValues }
      setDeviceSettings: (state, action) => {
        const i = state.device.findIndex( d => d.id === action.payload.id );
        const newSettings  = formatAlteredSettings(
            state.device[i].newSettings, 
            action.payload.jsonkeys, 
            action.payload.jsonValInd, 
            action.payload.values
          );
        state.device[i].newSettings = {...state.device[i].newSettings, newSettings};
        state.device[i].settingsState = "changed";
      },
      showAdvancedSet: (state, action) => {
        const i = state.device.findIndex( d => d.id === action.payload.id );
        const category = action.payload.category;
        const value = action.payload.value;
        state.device[i].data.showAdvanced[category] = value;
      },
      newSettingsRestored: (state, action) => {
        const i = state.device.findIndex( d => d.id === action.payload );
        state.device[i].newSettings = state.device[i].settings;
      },
      // settingsToBeSentReseted: (state, action) => {
      //   const i = state.device.findIndex( d => d.id === action.payload);
      //   state.device[i].settingsToBeSent = {};
      // },
      settingsSentTimeStampSet: (state, action) => {
        const i = state.device.findIndex( d => d.id === action.payload.deviceId);
        state.device[i].settingsSentTime = action.payload.timeStamp;
      },
      phonenumbersSet: (state, action) => {
        const i = state.device.findIndex( d => d.id === action.payload.deviceId );
        const numbers = action.payload?.phonenumbers.toSorted(phonenumberSortFunc);
        state.device[i].phonenumbers = numbers;
      },
      deviceNameSet: (state, action) => {
        const i = state.device.findIndex( d => d.id === action.payload.deviceId );
        state.device[i].name = action.payload.newName;
      },
      privilegesSet: (state, action) => {
        const i = state.device.findIndex( d => d.id === action.payload.deviceId );
        state.device[i].privileges = action.payload.privileges;
      },
    },
    extraReducers(builder) {
      builder
        .addCase(getDeviceData.pending, (state, action) => {
          state.status = 'loading';
        })
        .addCase(getDeviceData.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.test.push(action.payload.data[0]);
        })
        .addCase(getDeviceData.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        })
        .addCase(getPhoneNumbers.pending, (state, action) => {
          state.status = 'loading';
        })
        .addCase(getPhoneNumbers.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.test.push(action.payload.json());
        })
        .addCase(getPhoneNumbers.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        })
        .addCase(currentSettingsSet.fulfilled, (state, action) => {
          state.device[action.payload.i].newSettings = {
              ...state.device[action.payload.i].newSettings, 
              ...action.payload.newSettings
          };
          state.device[action.payload.i].settingsState = "changed";
          // state.device[action.payload.i].settingsToBeSent = 
          //   Object.assign({}, 
          //     state.device[action.payload.i].settingsToBeSent, 
          //     action.payload.newSettings
          //   );
        })
        .addCase(currentNewSettingsRestored.fulfilled, (state, action) => {
          const i = action.payload.i;
          state.device[i].newSettings = state.device[i].settings;
          //state.device[action.payload.i].settingsToBeSent = {};
        })
        .addCase(currentPhonenumbersSet.fulfilled, (state, action) => {
          state.device[action.payload.i].phonenumbers = action.payload?.newPhonenumbers.toSorted(phonenumberSortFunc);  
        })
        .addCase(sentSettings.pending, (state, action) => {
          state.status = "loading";  
        })
        .addCase(sentSettings.fulfilled, (state, action) => {
          state.device[action.payload.i].settingsState = "notChanged";  
        })
        .addCase(sentSettings.rejected, (state, action) => {
          state.device[action.payload.i].settingsState = "error";  
          state.error = action.payload.error;
        })
    } 
});

export const getDeviceDataStatus = (state) => state.devices.status;

export const selectAllDevices = (state) => state.devices.device;

export const selectDeviceListData = (state) => state.devices.device.map( dev => ({
    id:             dev.id,
    name:           dev.name,
    type:           dev.type,
    uuid:           dev.uuid,
    deviceStatus:   dev.deviceStatus,
    activationTime: dev.activationTime,
    lastSeen:       dev?.data?.latestValues?.time,
    offset:         dev?.utcZoneOffset,
    onomondoOK:     dev.simid != null,
    dataIsLoaded:   dev.dataIsLoaded,
    brand:          dev.brand,
  })
);

export const selectCurrentDeviceIndex = createSelector(
  [selectCurrentDeviceId, selectAllDevices], 
  (deviceId, devices) => devices.findIndex( d => d.id === deviceId )
);

export const selectCurrentDevice = createSelector(
  [selectCurrentDeviceIndex, selectAllDevices],
  (index, devices) => devices[index]
);

export const selectPrivileges = createSelector(
  [selectCurrentDeviceIndex, selectAllDevices],
  (index, devices) => devices[index].privileges
);

export const selectCurrentDataIsLoaded = createSelector(
  [selectCurrentDeviceIndex, selectAllDevices],
  (index, devices) => devices[index].dataIsLoaded
);

export const selectCurrentActivationTime = createSelector(
  [selectCurrentDeviceIndex, selectAllDevices],
  (index, devices) => devices[index].activationTime
);

export const selectCurrentReferenceTime = createSelector(
  [selectCurrentDeviceIndex, selectAllDevices],
  (index, devices) => devices[index]?.data?.referenceValues?.time
);

export const selectCurrentUTCzoneOffset = createSelector(
  [selectCurrentDeviceIndex, selectAllDevices],
  (index, devices) => devices[index]?.utcZoneOffset
);

export const selectCurrentNewSettingsId = createSelector(
  [selectCurrentDeviceIndex, selectAllDevices],
  (index, devices) => devices[index].newSettingsId
);

export const selectCurrentNewSettings = createSelector(
  [selectCurrentDeviceIndex, selectAllDevices],
  (index, devices) => devices[index].newSettings
);

// export const selectCurrentSettingsToBeSent = createSelector(
//   [selectCurrentDeviceIndex, selectAllDevices],
//   (index, devices) => devices[index].settingsToBeSent
// );

export const selectCurrentSettingsState = createSelector(
  [selectCurrentDeviceIndex, selectAllDevices],
  (index, devices) => devices[index].settingsState
);

export const selectCurrentSettingsSentTimeStamp = createSelector(
  [selectCurrentDeviceIndex, selectAllDevices],
  (index, devices) => devices[index].settingsSentTime
);

export const selectCurrentData = createSelector(
  [selectCurrentDeviceIndex, selectAllDevices],
  (index, devices) => devices[index].data
);

export const selectCurrentPhonenumbers = createSelector(
  [selectCurrentDeviceIndex, selectAllDevices],
  (index, devices) => devices[index].phonenumbers
);

export const selectCurrentCountry = createSelector(
  [selectCurrentDeviceIndex, selectAllDevices],
  (index, devices) => devices[index].country
);

export const selectCurrentComponentDesc = createSelector(
  [
    selectCurrentDevice,
    (_, componentId) => componentId
  ],
  (device, componentId) => device.uiDesc.find( o => o.id === componentId)
);

export const selectComponentValues = createSelector(
  [
    selectCurrentDevice,
    (_, componentId) => componentId
  ],
  (device, componentId) => {
    const valInd = device.uiDesc.find( o => o.id === componentId).JSONValInd;
    const jsonkeys = device.uiDesc.find( o => o.id === componentId).jsonkeys;
    return jsonkeys?.map( jsonkey => device.newSettings?.[jsonkey]?.[valInd] ?? null ) || [];
  }
);

export const selectDeviceIndexById = createSelector(
  [selectAllDevices, (state, deviceId) => deviceId],
  (devices, deviceId) => devices.findIndex( dev => dev.id === deviceId)
);


export const { 
  deviceAdded, 
  devicesAdded, 
  setDeviceSettings,
  //settingsToBeSentReseted,
  settingsSentTimeStampSet,
  showAdvancedSet,
  newSettingsRestored,
  phonenumbersSet,
  deviceNameSet,
  privilegesSet,
  dataRangeSet,
} = deviceSlice.actions;

export default deviceSlice.reducer;