import { withTranslation } from 'react-i18next';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {  Checkbox, 
          FormControl, 
          FormControlLabel, 
          Stack, 
          FormLabel }from '@mui/material/';

function PureCheckboxes({ t, block }) {
  const dispatch = useDispatch();

  const componentId = block.id;
  const deviceId = useSelector((state) => state.ui.currentDeviceId);
  const deviceIndex = useSelector((state) => state.devices.device.findIndex( d => d.id === deviceId ));
  const componentData = useSelector((state) => state.devices.device[deviceIndex].uiDesc.find( o => o.id === componentId));
  const desc = componentData.description;
  const labels = desc?.LABELS;
  const title = desc?.TITLE;
  const initialValues = [0,1] //newSettings[jsonkey][valInd];
  const [tempValue, setTempValue] = useState(initialValues);

  const handleclick = (event, newValue) => {
    setTempValue(newValue);
  };

  return (
    <FormControl style={{textAlign: "left", clear: "both", marginLeft: "1em"}}>
      <FormLabel>{t(title)}</FormLabel>
      <Stack direction="row" spacing={1} alignItems="center">
        {labels.map ( (label, i) =>
          <FormControlLabel key={label} value={i} control={<Checkbox onClick={handleclick}/>} label={t(label)} />
        )}
      </Stack>
    </FormControl>
  );
};

const Checkboxes = withTranslation()(PureCheckboxes);

export {
  Checkboxes
};
