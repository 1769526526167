import { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { selectCurrentActivationTime, selectCurrentReferenceTime } from '../data/devicesSlice.js';
import { timeRangeSet, selectLanguage, selectHourCycle } from '../data/uiSlice.js';
import { AlertError, AlertWarning } from './Alert.js';
import { customTimeRange } from '../utility/influxChartConfig.mjs';
import { ButtonWide } from './Button.js';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { DateTime } from 'luxon';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { PrettyTime } from './PrettyTime.js';
//import { enUS, deDE, fiFI, esES } from '@mui/x-date-pickers/locales';
// import 'dayjs/locale/de';
// import 'dayjs/locale/en';
// import 'dayjs/locale/fi';

// dayjs.extend(isSameOrBefore);
// dayjs.extend(duration);

export function PureTimeRangePicker({t, start, end, setStart, setEnd}) {
  const showTwelweHourClock = useSelector(selectHourCycle) === "h12";
  const language = useSelector(selectLanguage);

  return (
    <LocalizationProvider 
      dateAdapter={AdapterLuxon}  
      adapterLocale={language}
      
      >
      <MobileDateTimePicker
        value={start} 
        onChange={(newValue) => setStart(newValue)}
        label={t("range-start")}
        ampm={showTwelweHourClock}
        //orientation="landscape"
        />
      <MobileDateTimePicker
        value={end} 
        onChange={(newValue) => setEnd(newValue)}
        label={t("range-end" )}
        ampm={showTwelweHourClock}
        //orientation="landscape"
        />
    </LocalizationProvider>
  );
}
const TimeRangePicker = withTranslation()(PureTimeRangePicker);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

function PureDateTimePickerDialog({t, openDialog, setOpenDialog, setButtonIndex}) {
  const dispatch = useDispatch();
  const activationTimeStr = useSelector(selectCurrentActivationTime);
  const activationTime = DateTime.fromISO(activationTimeStr);
  const referenceTime = useSelector(selectCurrentReferenceTime);
  const now = DateTime.now();
  const startTime = now.minus({ day: 1 }) > activationTime ? now.minus({ day: 1 }) : activationTime;
  const [start, setStart] = useState(startTime);
  const [end, setEnd] = useState(now);
  const [errorMsg, setErrorMsg] = useState("");
  const [warnigMsg, setWarningMsg] = useState("");
  const [rangeOk, setRangeOk] = useState(false);

  useEffect(() => {
    if(start < activationTime){
      setStart(activationTime)
    }

    if(end < activationTime){
      setEnd(activationTime)
    }

    if(start <= activationTime || end <= activationTime){
      setWarningMsg(t("range-befor-activation-warning"));
    } 
    else {
      setWarningMsg(t(""));
    }

    if(start >= end){
      setRangeOk(false)
      setErrorMsg(t("range-start-end-error"));
    }
    else{
      setRangeOk(true)
      setErrorMsg(t(""));
    }
  }, [start, end]);

  const handleClose = () => {
    setOpenDialog(false);
  };
  const handleSaveDateTime = () => {
    setOpenDialog(false);
    dispatch(timeRangeSet(customTimeRange(start.toISO(), end.toISO())));
    setButtonIndex();
  };

  return (
    <div>
      
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Custom
      </Button> */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
      >
        <BootstrapDialogTitle color="secondary.main" id="customized-dialog-title" onClose={handleClose}>
          {t("select-range")}
        </BootstrapDialogTitle>
        <DialogContent>
          <TimeRangePicker start={start} end={end} setStart={setStart} setEnd={setEnd}/>
          <p>{t("device-activated")}: <PrettyTime>{activationTime}</PrettyTime></p>
          <p>{t("reference-measured")}: <PrettyTime>{referenceTime}</PrettyTime></p>
          <AlertError text={errorMsg} title={false} />
          <AlertWarning text={warnigMsg} title={false} />
        </DialogContent>
        <DialogActions>
          <ButtonWide 
              width="26%"  
            disabled={!rangeOk}
            autoFocus  
            onClick={handleSaveDateTime}
            text={t("get-data")}
            />
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
const DateTimePickerDialog = withTranslation()(PureDateTimePickerDialog);

export {
  DateTimePickerDialog
};