import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { selectCurrentDevice } from '../data/devicesSlice.js';
import { currentPageSet } from '../data/uiSlice.js';
import { InfluxCharts } from "../components/InfluxChart.js";

import { Typography } from '@mui/material';

function SubPageData({t}) {
  useDispatch()(currentPageSet("DATA"));
  const device = useSelector(selectCurrentDevice);

  return (
    <>
      <Typography variant="h2">{device.type}: {device.name }</Typography> 
      <InfluxCharts />
    </>
  );

}

export default withTranslation()(SubPageData);
