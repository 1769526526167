import { round } from '../utility/function.mjs' 

// alar limit types of cencors (SOURCE: data specification rev14)
const LIMIT_TYPE = {
  off: 0,
  absolute: 1, // "fixed limit"
  refFixed: 2, // "reference mixed limit" 
  relative: 3, // "referennce relative limits"
}

const RESULUTION = 500 // data points in aggregateWindow
const moreThanZero = (int) => {
  return int > 0 ? int : 1;
}
const calulateWindow = (hours) => {
  return moreThanZero(Math.round((hours * 3600)/RESULUTION)) + "s"
}


const DEFAULT_REFERENCE_TIME = "-1d";
const timeRanges = (referenceTime = DEFAULT_REFERENCE_TIME) => { 
  return [
    {
      name: 'three-hours',
      range: {
        start: '-3h',
        end: null
      },
      aggregateWindow: calulateWindow(3)
    },
    {
      name: 'one-day',
      range: {
        start: '-1d',
        end: null
      },
      aggregateWindow: calulateWindow(24)
    },
    {
      name: 'one-week',
      range: {
        start: '-7d',
        end: null
      },
      aggregateWindow: calulateWindow(7 * 24)
    },
    {
      name: 'one-month',
      range: {
        start: '-30d',
        end: null
      },
      aggregateWindow: calulateWindow(30 * 24)
    },
    {
      name: 'from-reference-time',
      range: {
        // if reference time is older than one month, start 30 days ago
        start: new Date().getTime() - new Date(referenceTime).getTime() > 2629800000 ? "-30d" : referenceTime,
        end: null
      },
      aggregateWindow: 
        referenceTime === '-1d' 
          ? calulateWindow(24)
          : moreThanZero(Math.round(((new Date().getTime()/1000) - (new Date(referenceTime).getTime()/1000))/RESULUTION)) + 's'
    },
  ];
}
// const timeRangeWithoutFunction = (i, referenceTime = DEFAULT_REFERENCE_TIME) => {
//   const {aggregateWindow, ...rest} = timeRanges(referenceTime)[i];
//   return {...rest};
// }

// TODO aggregateWindowin pitäisi mennä saadun datan mukaan ei haun mukaan
const customTimeRange = (start="", end="") => {
  return {
    name: 'custom-timerange',
    range: {
      start,
      end
    },
    aggregateWindow: moreThanZero(Math.round(((new Date(end).getTime()/1000) - (new Date(start).getTime()/1000))/RESULUTION)) + "s"
  }
}
// const customTimeRangeWithoutFunction = (start="", end="", diff="") => {
//   const {aggregateWindow, ...rest} = customTimeRange(start, end, diff);
//   return {...rest};
// }

const strokeColor = {
  temperature: "#F47174",
  pressure: "#6464ff",
};

const DEFAULT_UNIT = "SI"
const DECIMALS = 2;
const unit = {
  SI: {
    temperature: {
      symbol: '°C',
      conv: (x, _) => x,
      convBack: (x, decimals = DECIMALS) => round(x, decimals),
    },
    pressure: {
      symbol: 'bar',
      conv: (x, _) => x,
      convBack: (x, decimals = DECIMALS) => round(x, decimals),
    },
    batteryVoltage: {
      symbol: 'V',
      conv: (x, _) => x,
      convBack: (x, decimals = DECIMALS) => round(x, decimals),
    },
  },
  SAE: {
    temperature: {
      symbol: '°F',
      conv: (x, decimals = DECIMALS) => round((x * 1.8) + 32, decimals),
      convBack: (x, decimals = DECIMALS) => round((x - 32) / 1.8, decimals)
    },
    pressure: {
      symbol: 'psi',
      conv: (x, decimals = DECIMALS) => round(x * 14.5038, decimals),
      convBack: (x, decimals = DECIMALS) => round(x / 14.5038, decimals),
    },
    batteryVoltage: {
      symbol: 'V',
      conv: (x, _) => x,
      convBack: (x, decimals = DECIMALS) => round(x, decimals),
    },
  },
};

const unitWithOutConvs = {
  SI: {
    temperature: {
      symbol: '°C',
    },
    pressure: {
      symbol: 'bar',
    },
    batteryVoltage: {
      symbol: 'V',
    },
  },
  SAE: {
    temperature: {
      symbol: '°F',
    },
    pressure: {
      symbol: 'psi',
    },
    batteryVoltage: {
      symbol: 'V',
    },
  },
};

// const countryToUnitSystem = (country) => {
//   switch (country) {
//     case 'us':
//       return 'SAE'; 
//     default: // 'fi'
//       return 'SI';
//   };
// }

export {
  LIMIT_TYPE,
  strokeColor,
  // formatTime,
  timeRanges,
  //timeRangeWithoutFunction,
  customTimeRange,
 // customTimeRangeWithoutFunction,
  unit,
  unitWithOutConvs,
  DEFAULT_UNIT,
  //countryToUnitSystem,
}