import { createTheme } from '@mui/material/styles';

export const themesAndModes = (themeName, mode) => {
  if(theme?.[themeName]){
    if(theme[themeName]?.[mode]){
      return theme[themeName][mode];
    }
    return Object.values(theme[themeName])[0];
  }
  return theme.default.light;
}

export const checkMode = (themeName, mode) => {
  if(theme?.[themeName]){
    if(theme[themeName]?.[mode]){
      return mode;
    }
    return Object.keys(theme[themeName])[0];
  }
  return "light";
}

export const theme = {
  picote: {
    light: createTheme({
      brand: "picote",
      palette: {
        mode: 'light',
        primary: {
          main: '#d20630',
        },
        secondary: {
          main: '#d20630',
        },
        // warning: {
        //   main: '#d32f2f',
        // },
        // background: {
        //   default: '#242424',
        //   paper: '#f0eadf',
        //   light: "#ffffff"
        // },
        phonenumberTableRowBackground: {
          deleted: '#141414',
          notCheked: '#58156526',
          notValidated: '#8405052b',
          ok: '#7cff0314',
        }
      },
      typography: {
        h2: {
    //     color: '#d20630', //secondary.main
          textTransform: 'uppercase',
          textAlign: 'center',
          fontSize: '16px',
          fontWeight: 'normal',
          marginBottom: 10,
          fontFamily: '',
        },
        h4: {
          color: '#586E71',
          textAlign: 'center',
          fontSize: '16px',
          fontWeight: 'normal',
          marginBottom: 8,
        },
        fontFamily: [
          'Raleway', 
          'sans-serif',
        ].join(','),
      },
      '.tick text': {
        'position': 'absolute !important',
        'font-size': '12px'
      },
      '.tick text:hover': {
        'position': 'absolute !important',
        'z-index': '1000 !important',
        'font-size': '15px'
      },
    }),
    dark: createTheme({
      brand: "picote",
      palette: {
        mode: 'dark',
        primary: {
          main: '#d20630',
        },
        secondary: {
          main: '#c8baa1',
        },
        warning: {
          main: '#d32f2f',
        },
        background: {
          default: '#242424',
          //paper: '#f0eadf',
          light: "#eee"
        },
        phonenumberTableRowBackground: {
          deleted: '#141414',
          notCheked: '#58156526',
          notValidated: '#8405052b',
          ok: '#7cff0314',
        }
      },
      typography: {
        h2: {
          color: '#c8baa1', //secondary.main
          textTransform: 'uppercase',
          textAlign: 'center',
          fontSize: '16px',
          fontWeight: 'normal',
          marginBottom: 10,
          fontFamily: '',
        },
        h4: {
          textAlign: 'center',
          fontSize: '16px',
          fontWeight: 'normal',
          marginBottom: 8,
        },
        fontFamily: [
          'Raleway', 
          'sans-serif',
        ].join(','),
      },
      '.tick text': {
        'position': 'absolute !important',
        'font-size': '12px'
      },
      '.tick text:hover': {
        'position': 'absolute !important',
        'z-index': '1000 !important',
        'font-size': '15px'
      }
    })
  },
  default: {
    light: createTheme({
      palette: {
        brand: 'default',
        mode: 'light',
        primary: {
          main: '#4d8dc1',
        },
        secondary: {
          main: '#4d8dc1',
        },
        phonenumberTableRowBackground: {
          deleted: '#141414',
          notCheked: '#58156526',
          notValidated: '#8405052b',
          ok: '#7cff0314',
        }
      },
      typography: {
        h2: {
          color: '#586E71',
          textTransform: 'uppercase',
          textAlign: 'center',
          fontSize: '16px',
          fontWeight: 'normal',
          marginBottom: 10,
          fontFamily: '',
        },
        h4: {
          color: '#586E71',
          textAlign: 'center',
          fontSize: '16px',
          fontWeight: 'normal',
          marginBottom: 8,
        },
      },
      '.tick text': {
        'position': 'absolute !important',
        'font-size': '12px'
      },
      '.tick text:hover': {
        'position': 'absolute !important',
        'z-index': '1000 !important',
        'font-size': '15px'
      },
    }),
  }
  // const inputGlobalStyles = <GlobalStyles styles={{ 
//   '.xortecBack': {'background-color': '#4d8dc1 !important'},
//   '.picoteBack': {'background-color': '#d20630 !important'},
//   '.xortecFore': {'color': '#4d8dc1 !important'},
//   '.App': {'background-color': '#242424 !important', 'color': '#FFFFFF'},
//   h3: {
//     'text-transform': 'uppercase',
//     'color': '#FFFFFF',//'#586E71',
//     'text-align': 'center',
//     'font-size': '16px',
//     'font-weight': 'normal'
//   },
//   h4: {
//     'color': '#FFFFFF',//'#586E71',
//     'text-align': 'center',
//     'text-align': 'left',
//     'font-size': '16px',
//     'font-weight': 'normal'
//   },
//   '.tick text': {
//     'position': 'absolute !important',
//     'font-size': '12px'
//   },
//   '.tick text:hover': {
//     'position': 'absolute !important',
//     'z-index': '1000 !important',
//     'font-size': '15px'
//   }
// }} />;
} 

// const picoteThemeDark = createTheme({
//   brand: "picote",
//   palette: {
//     mode: 'dark',
//     primary: {
//       main: '#d20630',
//     },
//     secondary: {
//       main: '#c8baa1',
//     },
//     warning: {
//       main: '#d32f2f',
//     },
//     background: {
//       default: '#242424',
//       //paper: '#f0eadf',
//       light: "#ffffff"
//     },
//     phonenumberTableRowBackground: {
//       deleted: '#141414',
//       notCheked: '#58156526',
//       notValidated: '#8405052b',
//       ok: '#7cff0314',
//     }
//   },
//   typography: {
//     h2: {
//       color: '#c8baa1', //secondary.main
//       textTransform: 'uppercase',
//       textAlign: 'center',
//       fontSize: '16px',
//       fontWeight: 'normal',
//       marginBottom: 10,
//     },
//     fontFamily: [
//       'Raleway', 
//       'sans-serif',
//     ].join(','),
//   },
//   '.tick text': {
//     'position': 'absolute !important',
//     'font-size': '12px'
//   },
//   '.tick text:hover': {
//     'position': 'absolute !important',
//     'z-index': '1000 !important',
//     'font-size': '15px'
//   }
// });

// export const picoteThemeLight = createTheme({
//   brand: "picote",
//   palette: {
//     mode: 'light',
//     primary: {
//       main: '#d20630',
//     },
//     secondary: {
//       main: '#d20630',
//     },
//     // warning: {
//     //   main: '#d32f2f',
//     // },
//     background: {
//       //default: '#242424',
//       //paper: '#f0eadf',
//       //light: "#ffffff"
//     },
//     phonenumberTableRowBackground: {
//       deleted: '#141414',
//       notCheked: '#58156526',
//       notValidated: '#8405052b',
//       ok: '#7cff0314',
//     }
//   },
//   typography: {
//     h2: {
//  //     color: '#d20630', //secondary.main
//       textTransform: 'uppercase',
//       textAlign: 'center',
//       fontSize: '16px',
//       fontWeight: 'normal',
//       marginBottom: 10,
//     },
//     fontFamily: [
//       'Raleway', 
//       'sans-serif',
//     ].join(','),
//   },
//   '.tick text': {
//     'position': 'absolute !important',
//     'font-size': '12px'
//   },
//   '.tick text:hover': {
//     'position': 'absolute !important',
//     'z-index': '1000 !important',
//     'font-size': '15px'
//   },
// });

