import { useSelector } from "react-redux";

import { selectLanguage, selectBrand, selectLoginType } from "../data/uiSlice.js";
import { InfoDialog } from "./InfoDialog.js";
import terms from "../infos/Terms.js";
import privacyPolicy from "../infos/PrivacyPolicy.js";
import MainIoTLogo from '../utility/mainiot-logo.png';

import {
  Box,
  Link,
  Skeleton,
} from '@mui/material';

const INFO_BUTTON_TYPE = "footerLink";

const DefaultFooter = () => {
  const language = useSelector(selectLanguage);
  const loginType = useSelector(selectLoginType);

  if(loginType === "none"){
    return <Skeleton  sx={{mt:"auto"}} height={50} variant="rectangular" />;
  }
  return (
    <Box 
      sx={{
        mt:"auto",
        position: 'relative', 
        bgcolor: '#4d8dc1', 
        width: '100%', 
        height: '50px',
        backgroundColor: 'primary.main'
        }}>
        
    <Box sx={{position: 'absolute', left: '50%', top: '50%', 
        transform: 'translate(-50%,-50%)', width: 'max-content',
        'line-height': '14px', 'font-size': '13px', color: 'white', 
        'font-weight': 'bold'}}>
      <Box sx={{display: 'block', float: 'left', padding: '0 10px', 
          'border-right': '1px solid white'}}>
        <Box sx={{'text-align': 'right'}}>
          <span style={{'font-weight': 'normal'}} >Powered by</span><br/>
          <Link color="white" underline="hover" href="https://xortec.fi" target="_blank">XORTEC OY</Link>
        </Box>
      </Box>
      <Box sx={{display: 'block', float: 'left', padding: '0 10px',
          'border-right': '1px solid white'}}>
        <Box sx={{'text-align': 'center'}}>
        <span style={{'font-weight': 'normal'}} >Discover more: </span><br/>
          <Link color="white" underline="hover" href="https://main-iot.com" target="_blank">MAIN-IOT.COM</Link>
        </Box>
      </Box>
      <Box sx={{display: 'block', float: 'left', padding: '0 10px'}}>
        <Box sx={{'text-align': 'left'}}>
          <InfoDialog 
            data={{
              ...terms[language], 
              buttonType: INFO_BUTTON_TYPE
              }}  />
          <br/>
          <InfoDialog 
            data={{
              ...privacyPolicy[language], 
              buttonType: INFO_BUTTON_TYPE
              }} />
        </Box>
      </Box>
    </Box>
  </Box>
  )
}

const PicoteFooter = () => {
  const language = useSelector(selectLanguage);
  const loginType = useSelector(selectLoginType);

  if(loginType === "none"){
    return <Skeleton  sx={{mt:"auto"}} height={50} variant="rectangular" />;
  }
  return (
    <Box 
      sx={{
        mt:"auto",
        position: 'relative', 
        bgcolor: '#4d8dc1', 
        width: '100%', 
        height: '50px',
        backgroundColor: 'primary.main'
        }}>
      <Box sx={{position: 'absolute', left: '50%', top: '50%', 
          transform: 'translate(-50%,-50%)', width: 'max-content',
          'line-height': '14px', 'font-size': '13px', color: 'white', 
          'font-weight': 'bold'}}>
        <Box sx={{display: 'block', float: 'left', padding: '0 10px', 
            'border-right': '1px solid white'}}>
          <Box sx={{'text-align': 'right'}}>
            <span style={{'font-weight': 'normal'}} >Technology by</span><br/>
            <Link color="white" underline="hover" href="https://xortec.fi" target="_blank">Xortec</Link>
          </Box>
        </Box>
        <Box sx={{display: 'block', float: 'left', padding: '0 10px',
            'border-right': '1px solid white'}}>
          <Box sx={{'text-align': 'center'}}>
          <Link href="https://main-iot.com" target="_blank">
            <img 
              src={MainIoTLogo} 
              alt="Main-IoT" 
              style={{
                height: '20px',
                marginTop: '5px'
                // position: 'absolute', 
                // left: '50%', 
                // top: '50%', 
                // transform: 'translate(-50%,-50%)'
              }} />
              </Link>
          </Box>
        </Box>
        <Box sx={{display: 'block', float: 'left', padding: '0 10px'}}>
          <Box sx={{'text-align': 'left'}}>
            <InfoDialog 
              data={{
                ...terms[language], 
                buttonType: INFO_BUTTON_TYPE
                }}  />
            <br/>
            <InfoDialog 
              data={{
                ...privacyPolicy[language], 
                buttonType: INFO_BUTTON_TYPE
                }} />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const Footer = () => {
  const brand = useSelector(selectBrand);
  switch (brand) {
    case "picote":
      return <PicoteFooter />
    default:
      return <DefaultFooter />
  }
}
export default Footer;
