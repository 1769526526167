import { withTranslation } from 'react-i18next';
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { 
  countryToLanguage,
  countryToUnitSystem,
  countryToHourCycle
} from "../utility/userConfig.mjs";
import { 
  updateSuperadmin, 
  updatePin,
} from '../server/serverOperation.js';
import { selectCurrentPhonenumbers } from '../data/devicesSlice.js';
import { selectLanguage, selectCurrentDeviceId } from "../data/uiSlice.js";
import PhonenumberInput from "./Phonenumbers/PhonenumberInput.js";
import { ButtonWide } from "./Button.js";
import { HelpText } from './ReadMore.js';
import { LangaugeSelector } from './LanguageSelector.js';
import { AlertInfo } from './Alert.js';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material/';
import TelegramIcon from '@mui/icons-material/Telegram';

const userTypes = ['superadmin']

const PureSetupDialog = ({t}) => {
  const deviceId = useSelector(selectCurrentDeviceId);
  const language = useSelector(selectLanguage);
  const [newNumber, setNewNumber] = useState({number: "", country: ""});
  const phonenumbers = useSelector(selectCurrentPhonenumbers) || [];
  const initialOpen = !phonenumbers?.some(num => num.userType === "superadmin" && num?.telegramId !== null );
  const [openFirst, setOpenFirst] = useState(initialOpen);
  const [openSecond, setOpenSecond] = useState(false);
  const [pin, setPin] = useState("");
  const [pinIsOk, setPinIsOk] = useState(false);
  const [pinInputColor, setPinInputColor] = useState("grey");

  useEffect(() => {
    setOpenFirst(!phonenumbers.some(num => num.userType === "superadmin" && num?.telegramId !== null ));
  },[phonenumbers])

  const handlePinChange = (e) => {
    const valueIsValid = 
         e.target.value === ""
      || (/^[0-9]+$/.test(e.target.value) && e.target.value.length <= 6)
    if (valueIsValid){
      setPin(e.target.value);
    }
  }

  useEffect(() => {
    setPinIsOk(pin.length === 6);
    setPinInputColor(
      pin.length === 6 
        ? "green"
        : pin.length === 0
          ? "grey"
          : "red"
    );
  }, [pin])
  
  const handleNextClick = () => {
    const phonenumber = newNumber.number;
    const country = newNumber.country;
    const numberOk = phonenumber.length > 0 && country.length > 0;
    if(numberOk && pinIsOk){
      mutateUpdateSuperadmin({ 
        deviceId, 
        phonenumber, 
        country, 
        unitSystem: countryToUnitSystem(country),
        language: countryToLanguage(country),
        hourCycle: countryToHourCycle(country),
      });
      mutatePin({ 
        deviceId, 
        newPin: pin 
      });
    }
    if(!numberOk){
      toast.error(t("setup-number-error"), {
        toastId: "setup-number-error"
      });
    }
    if(!pinIsOk){
      toast.error(t("pin-error"), {
        toastId: "pin-error"
      });
    }
  }

  const { mutate:mutateUpdateSuperadmin, isLoading:updateSuperadminIsLoading, isSuccess:updateSuperadminIsSuccess } = useMutation(
    updateSuperadmin, // parameter {deviceId, phonenumber, country, language}
  );
  
  const { mutate:mutatePin, isLoading:setPinIsLoading, isSuccess:settingPinIsSuccess } = useMutation(
    updatePin, // parameter {deviceId, newPin}
  );

  useEffect(() => {
    if(updateSuperadminIsSuccess && settingPinIsSuccess){
      setOpenFirst(false);
      setOpenSecond(true);
    }
  }, [updateSuperadminIsSuccess, settingPinIsSuccess]);

  const handleBackClick = () => {
    setOpenFirst(true);
    setOpenSecond(false);
  }

  return (
    <>
      {/* FOR TESTING 
      <Button variant="outlined" onClick={handleClickOpen}>
        Open form dialog
      </Button> alariville: onClose={handleClose}*/}
      <Dialog 
        open={openFirst}
        maxWidth={"xs"}
      >
        <DialogTitle color="secondary.main">
          <Box sx={{maxWidth: "70%"}}>
            {t("setup-dialog-title")} 1/2
          </Box>
          <Box sx={{position: 'absolute',
            right: 10,
            top: 13,}}>
            <LangaugeSelector />
          </Box>
        </DialogTitle>
        <DialogContent>
          {t("setup-dialog-info-1")}
          <Typography variant="h2" sx={{mt: 3, textAlign: "left"}}>{t("phonenumber")}</Typography>
          <PhonenumberInput 
            number={newNumber.number} 
            setNewNumber={ setNewNumber } 
            useType={userTypes[0]} 
            setUserType={() => {}} 
            userTypes={userTypes} 
            inDialog
            defalutCountry={language}
            />
          <Typography variant="h2" sx={{mt: 3, textAlign: "left"}}>{t("pin-code")}</Typography>
          <p style={{maxWidth: "90%"}}>{t("setup-dialog-pin-text")}</p>
          <HelpText 
              text={t("setup-dialog-info-2-more")} 
              buttonStyle={{float:"right", marginTop: -65}}
              />
          <TextField
            id="newPin" 
            sx={{ input: { color: pinInputColor }} }
            type="text" 
            name="newPint"
            placeholder={t("create-new-pin")}
            value={pin}
            onChange={ handlePinChange }
            size="small"
          />
        </DialogContent>
        <DialogActions>
        <ButtonWide
          width="26%" 
          text="next" 
          onClick={ handleNextClick } 
          isLoading = { updateSuperadminIsLoading || setPinIsLoading }
        />
        </DialogActions>
      </Dialog>

      <Dialog 
        open={openSecond}
        onClose={ () => setOpenSecond(true) }
        maxWidth={"xs"}
      >
        <DialogTitle color="secondary.main">
          <Box sx={{maxWidth: "70%"}}>
            {t("setup-dialog-title")} 2/2
          </Box>
          <Box sx={{position: 'absolute', right: 10, top: 13,}}>
            <LangaugeSelector />
          </Box>
        </DialogTitle>
        <DialogContent>  
          <AlertInfo title={false} text={"setup-dialog-2-alertinfo"} />
          <p>{t("phonenumber")}: <b>{newNumber.number}</b> </p>{/*FOR USER GUIDE {newNumber.number} <b>(XXX) XXX-XXXX</b> */}
          <p>{t("setup-dialog-info-2")}</p>
        </DialogContent>  
        <DialogActions>
          <Box style={{margin: "auto"}}>
            <Button 
              style={{margin: "-15px 0px 20px 0px"}}
              className="xortecBack"
              href="https://t.me/MainIoT_bot?start" 
              variant="contained"
              endIcon={<TelegramIcon/>}
              >
              {t("activate-bot")}
            </Button> 
          </Box>
          <ButtonWide
            margin={"-15px 10px 20px 0px"}
            width="26%" 
            text="back" 
            onClick={ handleBackClick } 
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

const SetupDialog = withTranslation()(PureSetupDialog);
export default SetupDialog;