import { createSlice } from "@reduxjs/toolkit";

import { timeRanges } from '../utility/influxChartConfig.mjs';

const initialState = { 
  currentPage: "MAIN",
  currentDeviceId: 0,
  loginType: "none", // "none" | "landingPage" | "pin" | "telegram",
  language: "en",
  unitSystem: "SI", // "SI" | "SAE"
  hourCycle: "h12", // "h12" | "h23"
  timeRange: timeRanges()[4],
  brand: "default", // 
  themeMode: "light", // "light" || "dark"
  termsAccepted: false, 
  privacyPolicyAccepted: false,
};

export const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
      uiInit: (state, action) => {
        state.currentDeviceId           = action.payload.id           ?? state.currentDeviceId;
        state.loginType                 = action.payload.loginType    ?? state.loginType;
        state.userPhoneId               = action.payload.userPhoneId  ?? state.userPhoneId;
        state.language                  = action.payload.language     ?? state.language;
        state.unitSystem                = action.payload.unitSystem   ?? state.unitSystem;
        state.hourCycle                 = action.payload.hourCycle    ?? state.hourCycle;
        state.brand                     = action.payload.brand        ?? state.brand;
        if(action.payload?.privacyPolicyAccepted){   // check needed due to the type cast
          state.privacyPolicyAccepted   = !!action.payload.privacyPolicyAccepted;
        }
        if(action.payload?.termsAccepted){           // check needed due to the type cast
          state.termsAccepted           = !!action.payload.termsAccepted;
        }
      },
      brandSet: (state, action) => {
        state.brand = action.payload;
      },
      currentPageSet: (state, action) => {
        state.currentPage = action.payload;
      },
      currentDeviceSet: (state, action) => {
        state.currentDeviceId = action.payload;
      },
      dataRangeSet: (state, action) => {
        state.dataRange = action.payload;
      },
      timeRangeSet: (state, action) => {
        state.timeRange = action.payload;
      },
      hourCycleSet: (state, action) => {
        state.hourCycle = action.payload;
      },
      languageSet: (state, action) => {
        state.language = action.payload.slice(0,2);
      },
      unitSystemSet: (state, action) => {
        state.unitSystem = action.payload;
      },
      loginTypeSet: (state, action) => {
        if(["landingPage", "pin", "telegram"].includes(action.payload)){
          state.loginType = action.payload;
        }
      },
      termsAcceptedSet: (state, action) => {
        state.termsAccepted = action.payload;
      },
      privacyPolicyAcceptedSet: (state, action) => {
        state.privacyPolicyAccepted = action.payload;
      },
      themeModeSet: (state, action) => {
        state.themeMode = action.payload;
      },
    }
});

export const selectCurrentPage = state => state.ui.currentPage;
export const selectCurrentDeviceId = state => state.ui.currentDeviceId;
export const selectUserPhoneId = state => state.ui.userPhoneId;
export const selectLoginType = state => state.ui.loginType;
export const selectTimeRange = state => state.ui.timeRange;
export const selectLanguage = state => state.ui.language;
export const selectLocale = 
  state => {
    switch (state.ui.language) {
      case 'fi':
        return 'fi-FI' 
      default: // 'en'
        return 'en-GB'
    }
  }
export const selectUnitSystem = state => state.ui.unitSystem;
export const selectHourCycle = state => state.ui.hourCycle;
export const selectTermsAccepted = state => state.ui.termsAccepted;
export const selectPrivacyPolicyAccepted = state => state.ui.privacyPolicyAccepted;
export const selectBrand = state => state.ui.brand;
export const selectThemeMode = state => state.ui.themeMode;

export const {
  uiInit,
  brandSet,
  currentPageSet,
  currentDeviceSet,
  timeRangeSet,
  dataRangeSet,
  languageSet,
  unitSystemSet,
  hourCycleSet,
  loginTypeSet,
  themeModeSet,
  termsAcceptedSet,
  privacyPolicyAcceptedSet,
} = uiSlice.actions;

export default uiSlice.reducer;